import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { BotaoVoltar } from 'components/common/buttons/BotaoVoltar';

const ModalConfirmacao = ({ titulo, mensagem, url, setModalValue }) => {
	const handleIrParaSite = () => {
		window.location.href = url;
	};
	const handleVoltar = () => {
		return setModalValue((v) => ({
			...v,
			open: false,
		}));
	};
	return (
		<Dialog open={true} onClose={handleVoltar} maxWidth='string'>
			<DialogTitle>{titulo}</DialogTitle>
			<DialogContent>
				<p>{mensagem}</p>
			</DialogContent>
			<DialogActions>
				<BotaoVoltar onClick={handleVoltar} color='primary'>
					Voltar
				</BotaoVoltar>
				<Button onClick={handleIrParaSite} color='primary' size='small' variant='contained'>
					Ir para o site da Cora
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ModalConfirmacao;
