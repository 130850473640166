import React from 'react';

import { Typography, FormGroup, Checkbox, Box, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { RelatorioInputDate } from 'components/resources/relatorios/dateUtils';
import { BoletosContext } from '../abas/ContasReceberBoletos/BoletosContextProvider';

const boxStyles = makeStyles(() => ({
	typography: {
		lineHeight: '250%',
	},
}));

const BoxConfigDevedores = () => {
	const {
		opcoesImpressao: { imprimir_devedores },
		setDataDevedores,
		dataDevedores,
		handleChangeOpcoesImpressao,
	} = React.useContext(BoletosContext);

	const style = boxStyles();

	return (
		<>
			<Typography className={style.typography} variant='h6'>
				Devedores
			</Typography>
			<Box flex={1}>
				<RelatorioInputDate
					label='Devedores até dia'
					handleChange={(_: any, newValue: string) => {
						setDataDevedores(newValue);
					}}
					value={dataDevedores}
					fullWidth
				/>
			</Box>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimir_devedores}
							onChange={(e, checked) => handleChangeOpcoesImpressao(checked, 'imprimir_devedores')}
							name='imprimir_devedores'
						/>
					}
					label='Imprimir devedores'
				/>
			</FormGroup>
		</>
	);
};

export default BoxConfigDevedores;
