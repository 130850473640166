import React from 'react';

import { InstrucoesRemessaSemDiasFields } from '../../../../../commons/InstrucoesRemessaFields';

import { GetOnChangeProps, ChangeProps, IChoiceOption, inputProps } from '../../../../../types';

const emptyProps = {} as any;

const MultaFields = ({ propsTipo = emptyProps, propsValor = emptyProps }) => (
	<InstrucoesRemessaSemDiasFields
		sourcePrincipal='multa'
		propsTipo={{
			source: 'multa_tipo',
			label: 'Multa',
			...propsTipo,
		}}
		propsValor={{
			source: 'multa_valor',
			...propsValor,
		}}
	/>
);

export const getPropsOnChangeTipoPadraoMulta = ({
	sources: { sourceValor } = emptyProps,
	values,
	optionsIsencoesTipo,
}: GetOnChangeProps) => ({
	values: { [sourceValor]: values[sourceValor] },
	sources: { sourceValor },
	optionsIsencoesTipo,
});

export const createOnChangeTipoPadraoMulta =
	(
		{ sources: { sourceValor } = emptyProps, values, pristine, optionsIsencoesTipo }: GetOnChangeProps,
		change: ChangeProps
	) =>
	(_: any, tipoValue: IChoiceOption, input: inputProps) => {
		input.onChange(tipoValue);
		const valorValue = values[sourceValor];
		if (!tipoValue || optionsIsencoesTipo[tipoValue.id]) {
			sourceValor && change(sourceValor, undefined);
		} else {
			if (tipoValue.id === 'PRC') {
				!valorValue && change(sourceValor, undefined);
			} else {
				!pristine && !valorValue && change(sourceValor, undefined);
			}
		}
	};

export default MultaFields;
