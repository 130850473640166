/* eslint-disable prettier/prettier */
import { DefaultContaCaixaProps } from './default';
import { AilosContaNaoCorrenteProps, AilosContaCorrenteProps } from './ailos_085';
import { BancoDoBrasilContaNaoCorrenteProps, BancoDoBrasilContaCorrenteProps } from './banco_do_brasil_001';
import { BanrisulContaNaoCorrenteProps, BanrisulContaCorrenteProps } from './banrisul_041';
import { BradescoContaNaoCorrenteProps, BradescoContaCorrenteProps } from './bradesco_237';
import { CaixaContaNaoCorrenteProps, CaixaContaCorrenteProps } from './caixa_104';
import { ItauContaNaoCorrenteProps, ItauContaCorrenteProps } from './itau_341';
import { SantanderContaNaoCorrenteProps, SantanderContaCorrenteProps } from './santander_033';
import { SicoobContaNaoCorrenteProps, SicoobContaCorrenteProps } from './sicoob_756';
import { SicrediContaNaoCorrenteProps, SicrediContaCorrenteProps } from './sicredi_748';
import { UnicredContaNaoCorrenteProps, UnicredContaCorrenteProps } from './unicred_136';
import { GerencianetContaNaoCorrenteProps, GerencianetContaCorrenteProps } from './gerencianet_364';
import { InterContaNaoCorrenteProps, InterContaCorrenteProps } from './banco_inter_077';
import { BanestesContaCorrenteProps, BanestesContaNaoCorrenteProps } from './banestes_021';
import { CresolContaCorrenteProps, CresolContaNaoCorrenteProps } from './cresol_133';
import { CondoContaCorrenteProps, CondoContaNaoCorrenteProps } from './condoconta_450';
import { ContBankCorrenteProps, ContBankNaoCorrenteProps } from './contbank_332';
import { BancoCoraCorrenteProps } from './cora_403';
export const ContaCaixaPropsByInstituicao = {
	default: DefaultContaCaixaProps,
};

export const ContaNaoCorrentePropsByInstituicao = {
	'085': AilosContaNaoCorrenteProps,
	'001': BancoDoBrasilContaNaoCorrenteProps,
	'041': BanrisulContaNaoCorrenteProps,
	237: BradescoContaNaoCorrenteProps,
	104: CaixaContaNaoCorrenteProps,
	341: ItauContaNaoCorrenteProps,
	'033': SantanderContaNaoCorrenteProps,
	756: SicoobContaNaoCorrenteProps,
	748: SicrediContaNaoCorrenteProps,
	136: UnicredContaNaoCorrenteProps,
	364: GerencianetContaNaoCorrenteProps,
	'077': InterContaNaoCorrenteProps,
	'021': BanestesContaNaoCorrenteProps,
	133: CresolContaNaoCorrenteProps,
	450: CondoContaNaoCorrenteProps,
	332: ContBankNaoCorrenteProps,
};

export const ContaCorrentePropsByInstituicao = {
	'085': AilosContaCorrenteProps,
	'001': BancoDoBrasilContaCorrenteProps,
	'041': BanrisulContaCorrenteProps,
	237: BradescoContaCorrenteProps,
	104: CaixaContaCorrenteProps,
	341: ItauContaCorrenteProps,
	'033': SantanderContaCorrenteProps,
	756: SicoobContaCorrenteProps,
	748: SicrediContaCorrenteProps,
	136: UnicredContaCorrenteProps,
	364: GerencianetContaCorrenteProps,
	'077': InterContaCorrenteProps,
	'021': BanestesContaCorrenteProps,
	133: CresolContaCorrenteProps,
	450: CondoContaCorrenteProps,
	332: ContBankCorrenteProps,
	403: BancoCoraCorrenteProps,
};
