import React, { useEffect } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { PrevisaoOrcamentariaContext } from './contexts/PrevisoesOrcamentariasContextProvider';
import { TreeTableContext } from './contexts/TreeTableContextProvider';
import { parseInitials } from '../planosCondominio/helpers/parseInitials';
import { InputMonetario } from 'components/common/filtros/DecimalV2';
import { IPrevisaoOrcamentariaRowProps } from './types';
import { calculaValoresMensal, calculaValoresPeriodo, processTreeBottomToTop } from './formatadores';
import ValorField from './ValorField';

const PrevisaoOrcamentariaRow: React.FC<IPrevisaoOrcamentariaRowProps> = ({ row, RowsRendererComponent }) => {
	const { propriedadesContasView, setPropriedadesContasView } = React.useContext(TreeTableContext);
	const { modeloSelecionado, setModeloSelecionado, periodo, previsaoOrcamentariaTree, setPrevisaoOrcamentariaTree } =
		React.useContext(PrevisaoOrcamentariaContext);

	const handleExpand = (row: any) => {
		if (row.children.length > 0) {
			setPropriedadesContasView({
				...propriedadesContasView,
				openChildren: {
					...propriedadesContasView.openChildren,
					[row.id_concatenado]: !propriedadesContasView.openChildren[row.id_concatenado],
				},
			});
		}
	};
	useEffect(() => {
		setModeloSelecionado({
			...modeloSelecionado,
			periodo: periodo,
		});
		for (const node of previsaoOrcamentariaTree) {
			processTreeBottomToTop(
				previsaoOrcamentariaTree,
				node,
				propriedadesContasView,
				modeloSelecionado,
				setPrevisaoOrcamentariaTree
			);
		}
	}, [periodo]);

	return (
		<React.Fragment key={row.id_concatenado}>
			<TableRow hover style={{ overflow: 'hidden' }}>
				<TableCell
					style={{
						paddingLeft: row.level * 20 + 36,
						position: 'relative',
					}}
				>
					{row.children.length > 0 ? (
						<IconButton
							className='tree-icon-table'
							onClick={() => handleExpand(row)}
							style={{
								padding: 10,
								position: 'absolute',
								left: row.level * 16 + 5,
								top: 'calc(50% - 20px)',
							}}
						>
							<ArrowForwardIosIcon
								style={{
									height: 18,
									width: 18,
									transition: 'all 200ms !important',
									transform: propriedadesContasView.openChildren[row.id_concatenado]
										? 'rotate(90deg)'
										: 'rotate(0deg)',
								}}
							/>
						</IconButton>
					) : (
						<></>
					)}
					<span
						style={{
							fontWeight: row.classe_conta === 'T' ? 'bold' : 'normal',
						}}
					>
						{row.nome}
					</span>
				</TableCell>
				<TableCell>{parseInitials(row.tipo)}</TableCell>

				<TableCell
					align='right'
					style={{
						fontWeight: row.classe_conta === 'T' ? 'bold' : 'normal',
					}}
				>
					{(row.classe_conta == 'A' && (
						<InputMonetario
							value={row.valorPeriodo}
							onChange={(valor) => {
								calculaValoresPeriodo(
									previsaoOrcamentariaTree,
									modeloSelecionado,
									valor || 0,
									row,
									setPrevisaoOrcamentariaTree
								);
								calculaValoresMensal(
									previsaoOrcamentariaTree,
									modeloSelecionado,
									(valor || 0) / periodo,
									row,
									setPrevisaoOrcamentariaTree
								);
							}}
						/>
					)) ||
						(row.classe_conta == 'T' && <ValorField value={row.valorPeriodo / 100} prefix={'R$ '} />)}
				</TableCell>
				<TableCell
					align='right'
					style={{
						fontWeight: row.classe_conta === 'T' ? 'bold' : 'normal',
					}}
				>
					{(row.classe_conta == 'A' && (
						<InputMonetario
							value={row.valorMensal}
							onChange={(valor: any) => {
								calculaValoresMensal(
									previsaoOrcamentariaTree,
									modeloSelecionado,
									valor || 0,
									row,
									setPrevisaoOrcamentariaTree,
									true
								);
							}}
						/>
					)) ||
						(row.classe_conta == 'T' && <ValorField value={row.valorMensal / 100} prefix={'R$ '} />)}
				</TableCell>

				<TableCell
					align='right'
					style={{
						fontWeight: row.classe_conta === 'T' ? 'bold' : 'normal',
					}}
				>
					{<ValorField value={row.percentual} suffix={'%'} />}
				</TableCell>
			</TableRow>
			{row.children.length > 0 && propriedadesContasView.openChildren[row.id_concatenado] ? (
				<RowsRendererComponent key={row.id_concatenado + 'children'} treeData={row.children} />
			) : (
				<></>
			)}
		</React.Fragment>
	);
};

export default PrevisaoOrcamentariaRow;
