import { PrevisaoOrcamentariaContext } from './contexts/PrevisoesOrcamentariasContextProvider';
import React, { useEffect, useContext } from 'react';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { TreeTableContext } from './contexts/TreeTableContextProvider';
import { IPrevisaoOrcamentariaWithDadosContaNode } from './types';
import PrevisaoOrcamentariaRow from './PrevisaoOrcamentariaRow';
import { processTreeBottomToTop } from './formatadores';

export const MensagemPlanoCondominioVazio = () => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				textAlign: 'center',
				width: '100%',
				height: '100%',
			}}
		>
			<Typography variant='h6'>Não há nenhuma conta cadastrada</Typography>
		</div>
	);
};

export const ContasTable = () => {
	const { previsaoOrcamentariaTree } = useContext(PrevisaoOrcamentariaContext);

	if (!previsaoOrcamentariaTree.length) return <MensagemPlanoCondominioVazio />;

	return (
		<Table stickyHeader>
			<TableHead>
				<TableRow>
					<TableCell style={{ background: '#fff' }}>Nome da conta</TableCell>
					<TableCell style={{ background: '#fff' }}>Tipo</TableCell>
					<TableCell align='right' style={{ background: '#fff' }}>
						Valor Total do Período
					</TableCell>
					<TableCell align='right' style={{ background: '#fff' }}>
						Valor Mensal
					</TableCell>
					<TableCell align='right' style={{ background: '#fff' }}>
						Percentual %
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{previsaoOrcamentariaTree.length > 0 && previsaoOrcamentariaTree[0].id !== 0 ? (
					<RowsRenderer key={'root'} treeData={previsaoOrcamentariaTree} />
				) : (
					<tr />
				)}
			</TableBody>
		</Table>
	);
};

export const RowsRenderer = ({ treeData }: { treeData: IPrevisaoOrcamentariaWithDadosContaNode[] }) => {
	const { previsaoOrcamentariaTree, setPrevisaoOrcamentariaTree, modeloSelecionado } =
		useContext(PrevisaoOrcamentariaContext);
	const { propriedadesContasView } = useContext(TreeTableContext);
	useEffect(() => {
		for (const node of treeData)
			processTreeBottomToTop(
				previsaoOrcamentariaTree,
				node,
				propriedadesContasView,
				modeloSelecionado,
				setPrevisaoOrcamentariaTree
			);
	}, [modeloSelecionado]);
	return (
		<>
			{treeData.map((row: any) => (
				<PrevisaoOrcamentariaRow row={row} RowsRendererComponent={RowsRenderer} />
			))}
		</>
	);
};
