import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useNotify } from 'react-admin';

import { format, isBefore, parse, set, setDay, addMonths, addWeeks, addDays, getDay, getDate, nextDay } from 'date-fns';

import { LancamentoContaPagarContext } from '../LancamentoContaPagarContext';
import { dictFunctionsVencimentoPrevisto } from '../ajusteDomingosFeriados';
import decimalAdjust from '../../../common/decimalAdjust';

const formatISOSimples = (date) => format(date, 'yyyy-MM-dd');
const parseISOSimples = (string) => parse(string, 'yyyy-MM-dd', new Date());
const decimalAdjustFloor = (value, roundDigitNumber = -2, type = 'floor') =>
	decimalAdjust(type, value, roundDigitNumber);

export const FormParcelamentoContext = createContext();

export const FormParcelamentoContextProvider = ({ children, context }) => {
	const notify = useNotify();
	const {
		dataVencimento,
		setDataVencimento,
		parcelasPagas,
		valorPago,
		parcelas,
		setParcelas,
		modificadorVencimento,
		setModificadorVencimento,
		tipoParcelamento,
		setTipoParcelamento,
		dadosCalculoVencimento,
		setDadosCalculoVencimento,
		quantidadeParcelas,
		setQuantidadeParcelas,
		valorTotal,
	} = useContext(LancamentoContaPagarContext);
	const { setModalValue } = useContext(context);
	const [dataVencimentoToken, setDataVencimentoToken] = useState(dataVencimento);
	const dataVencimentoRef = useRef(dataVencimento);
	const [modificadorVencimentoToken, setModificadorVencimentoToken] = useState(modificadorVencimento);
	const modificadorVencimentoRef = useRef(modificadorVencimento);
	const [tipoParcelamentoToken, setTipoParcelamentoToken] = useState(tipoParcelamento);
	const tipoParcelamentoRef = useRef(tipoParcelamento);
	const [dadosCalculoVencimentoToken, setDadosCalculoVencimentoToken] = useState(dadosCalculoVencimento);
	const dadosCalculoVencimentoRef = useRef(dadosCalculoVencimento);
	const [quantidadeParcelasToken, setQuantidadeParcelasToken] = useState(quantidadeParcelas);
	const [maxQuantidadeParcelas] = useState(parseInt(valorTotal * 100));
	const quantidadeParcelasRef = useRef(quantidadeParcelas);
	const [parcelasToken, setParcelasToken] = useState(parcelas);
	const [valorPorParcela, setValorPorParcela] = useState(
		decimalAdjustFloor(
			decimalAdjustFloor(valorTotal - valorPago) / (quantidadeParcelasToken - parcelasPagas.length)
		)
	);

	const [recalculado, setRecalculado] = useState(false);
	const [precisaRecalcular, setPrecisaRecalcular] = useState(false);
	const [erros, setErros] = useState({});

	useEffect(() => {
		const { dia, dia_semana, dias } = dadosCalculoVencimentoToken;
		const { dia: diaRef, dia_semana: dia_semanaRef, dias: diasRef } = dadosCalculoVencimentoRef.current;
		setPrecisaRecalcular(
			Boolean(
				dataVencimentoToken !== dataVencimentoRef.current ||
					modificadorVencimentoToken !== modificadorVencimentoRef.current ||
					tipoParcelamentoToken !== tipoParcelamentoRef.current ||
					(tipoParcelamentoToken === 'M'
						? dia !== diaRef
						: tipoParcelamentoToken === 'S'
						? dia_semana !== dia_semanaRef
						: dias !== diasRef) ||
					quantidadeParcelasToken !== quantidadeParcelasRef.current
			)
		);
	}, [
		dataVencimentoToken,
		modificadorVencimentoToken,
		tipoParcelamentoToken,
		dadosCalculoVencimentoToken,
		quantidadeParcelasToken,
	]);
	const updateValorPorParcela = useCallback(() => {
		setValorPorParcela(
			decimalAdjustFloor(
				decimalAdjustFloor(valorTotal - valorPago) / (quantidadeParcelasToken - parcelasPagas.length)
			)
		);
	}, [setValorPorParcela, valorTotal, quantidadeParcelasToken, parcelasPagas, valorPago]);
	useEffect(updateValorPorParcela, [quantidadeParcelasToken]);
	useEffect(() => setQuantidadeParcelas(parcelas.length), [setQuantidadeParcelas, parcelas.length]);

	const calcular = useCallback(() => {
		if (erros && Object.values(erros).length) {
			notify(Object.values(erros)[0], 'warning');
		} else {
			let dateVencimento = parse(dataVencimentoToken, 'dd/MM/yyyy', new Date());
			let formatVencimento = () => ({});
			const functionVencimentoPrevisto =
				dictFunctionsVencimentoPrevisto[modificadorVencimentoToken] || ((date) => date);
			if (tipoParcelamentoToken === 'M') {
				dateVencimento = set(
					dadosCalculoVencimentoToken.dia < getDate(dateVencimento)
						? addMonths(dateVencimento, 1)
						: dateVencimento,
					{ date: dadosCalculoVencimentoToken.dia }
				);
				formatVencimento = (index) => {
					const dataVencimento = addMonths(dateVencimento, index);
					return {
						data_vencimento_base: formatISOSimples(dataVencimento),
						data_vencimento: formatISOSimples(functionVencimentoPrevisto(dataVencimento)),
					};
				};
			} else if (tipoParcelamentoToken === 'S') {
				const weekDay = dadosCalculoVencimentoToken.dia_semana - 1;
				dateVencimento =
					weekDay < getDay(dateVencimento)
						? nextDay(dateVencimento, weekDay)
						: setDay(dateVencimento, weekDay);
				formatVencimento = (index) => {
					const dataVencimentoISO = formatISOSimples(addWeeks(dateVencimento, index));
					return {
						data_vencimento_base: dataVencimentoISO,
						data_vencimento: dataVencimentoISO,
					};
				};
			} else if (tipoParcelamentoToken === 'E') {
				const dias = dadosCalculoVencimentoToken.dias;
				formatVencimento = (index) => {
					const dataVencimento = addDays(dateVencimento, parseInt(dias * index));
					return {
						data_vencimento_base: formatISOSimples(dataVencimento),
						data_vencimento: formatISOSimples(functionVencimentoPrevisto(dataVencimento)),
					};
				};
			}

			const novoArrayParcelas = Array.from(Array(quantidadeParcelasToken), (_, i) => {
				return parcelasPagas[i] && parcelasPagas[i].situacao !== 'P'
					? parcelasPagas[i]
					: { valor_pendente: valorPorParcela };
			});

			let sobrasTotal = novoArrayParcelas.reduce(
				(sobrasTotal, parcela) => decimalAdjust('round', sobrasTotal - parcela.valor_pendente, -2),
				valorTotal
			);

			const formatValor = (sobrasTotal = 0) => {
				const valor_pendente =
					sobrasTotal >= 0 ? decimalAdjust('round', valorPorParcela + 0.01, -2) : valorPorParcela;
				return {
					valor_pendente: valor_pendente,
					porcentagem: decimalAdjust('round', (valor_pendente / valorTotal) * 100, -2),
				};
			};
			setParcelasToken(
				novoArrayParcelas
					.map((p, i) => {
						if (p.situacao && p.situacao !== 'P') return p;
						sobrasTotal = decimalAdjust('round', sobrasTotal - 0.01, -2);
						return {
							situacao: 'P',
							editada: false,
							...formatValor(sobrasTotal),
							...formatVencimento(i),
						};
					})
					.sort((PA, PP) =>
						isBefore(parseISOSimples(PA.data_vencimento), parseISOSimples(PP.data_vencimento)) ? -1 : 1
					)
					.map((p, i) => {
						p.numero_parcela = i + 1;
						return p;
					})
			);
			dadosCalculoVencimentoRef.current.dia = dadosCalculoVencimentoToken.dia;
			dadosCalculoVencimentoRef.current.dia_semana = dadosCalculoVencimentoToken.dia_semana;
			dadosCalculoVencimentoRef.current.dias = dadosCalculoVencimentoToken.dias;
			dataVencimentoRef.current = dataVencimentoToken;
			modificadorVencimentoRef.current = modificadorVencimentoToken;
			tipoParcelamentoRef.current = tipoParcelamentoToken;
			quantidadeParcelasRef.current = quantidadeParcelasToken;
			setPrecisaRecalcular(false);
		}
	}, [
		erros,
		notify,
		dataVencimentoToken,
		modificadorVencimentoToken,
		tipoParcelamentoToken,
		dadosCalculoVencimentoToken.dia,
		dadosCalculoVencimentoToken.dia_semana,
		dadosCalculoVencimentoToken.dias,
		valorTotal,
		setParcelasToken,
		valorPorParcela,
		quantidadeParcelasToken,
		setPrecisaRecalcular,
		parcelasPagas,
	]);

	const closeModal = useCallback(() => {
		setModalValue((v) => ({ ...v, open: false }));
	}, [setModalValue]);

	const handleSave = useCallback(() => {
		if (precisaRecalcular) {
			notify('A configuração de parcelamento foi alterada, clique no botão Calcular', 'warning');
		} else if (erros && Object.values(erros).length) {
			notify(Object.values(erros)[0], 'warning');
		} else {
			setModificadorVencimento(modificadorVencimentoToken);
			setTipoParcelamento(tipoParcelamentoToken);
			setDadosCalculoVencimento(dadosCalculoVencimentoToken);
			setDataVencimento(dataVencimentoToken);
			setParcelas(parcelasToken);
			notify('Parcelamento concluído com sucesso');
			setModalValue((v) => ({ ...v, open: false }));
		}
	}, [
		precisaRecalcular,
		setModificadorVencimento,
		modificadorVencimentoToken,
		setTipoParcelamento,
		tipoParcelamentoToken,
		setDadosCalculoVencimento,
		dadosCalculoVencimentoToken,
		setDataVencimento,
		dataVencimentoToken,
		setParcelas,
		parcelasToken,
		erros,
		notify,
		setModalValue,
	]);

	const FormParcelamentoContextValue = useMemo(
		() => ({
			dataVencimentoToken,
			setDataVencimentoToken,
			modificadorVencimentoToken,
			setModificadorVencimentoToken,
			tipoParcelamentoToken,
			setTipoParcelamentoToken,
			dadosCalculoVencimentoToken,
			setDadosCalculoVencimentoToken,
			quantidadeParcelasToken,
			setQuantidadeParcelasToken,
			maxQuantidadeParcelas,
			parcelasToken,
			setParcelasToken,
			recalculado,
			setRecalculado,

			calcular,
			erros,
			setErros,
			handleSave,
			closeModal,
		}),
		[
			dataVencimentoToken,
			setDataVencimentoToken,
			modificadorVencimentoToken,
			setModificadorVencimentoToken,
			tipoParcelamentoToken,
			setTipoParcelamentoToken,
			dadosCalculoVencimentoToken,
			setDadosCalculoVencimentoToken,
			quantidadeParcelasToken,
			setQuantidadeParcelasToken,
			maxQuantidadeParcelas,
			parcelasToken,
			setParcelasToken,
			recalculado,
			setRecalculado,

			calcular,
			erros,
			setErros,
			handleSave,
			closeModal,
		]
	);

	return (
		<FormParcelamentoContext.Provider value={FormParcelamentoContextValue}>
			{children}
		</FormParcelamentoContext.Provider>
	);
};
