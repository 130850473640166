import { TFunctionValidate, IGetValidacoesDescontoProps } from '../../../types';

import {
	validacoesFieldsTabInformacao,
	createValidateLinhas,
	validateTipoIntegracao,
	createValidateDadosIntegracaoAPI,
	validateRequiredField,
} from '../../../utils/validadoes';

import {
	getValidateQuandoEmitindoBoletos,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
} from '../../default/ContaCorrenteProps';

const validacoesMulta = getValidacoesMulta({
	dias: false,
});

const validacoesJuros = getValidacoesJuros({
	dias: false,
});

type TGetValidacoesDesconto = (props: IGetValidacoesDescontoProps) => ReturnType<typeof getValidacoesDesconto>;

const validacoesDesconto = (getValidacoesDesconto as TGetValidacoesDesconto)({
	tipo: true,
	valor: true,
	dias: true,
	minValor: 0.01,
	msgErroMinValor: 'O valor do desconto à ser aplicado não pode ser menor que 0.01',
});

const validateContaCedente: TFunctionValidate = ({ values }) =>
	validateRequiredField({
		values,
		field: 'conta_cedente',
		msg: 'Para emitir boletos, é requerido o número da conta cedente',
	});

const validacoesInstrucoesCobrancaRemessa = [
	validacoesMulta.tipo,
	validacoesMulta.valor,

	validacoesJuros.tipo,
	validacoesJuros.valor,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,
];

const validateDadosIntegracaoAPI = createValidateDadosIntegracaoAPI();

const validateLinhas = createValidateLinhas({ numeroLinhas: 5, maxValue: 80 });

const validateQuandoEmitindoBoletos = getValidateQuandoEmitindoBoletos([
	validateContaCedente,
	validateTipoIntegracao,
	validateDadosIntegracaoAPI,
	...validacoesInstrucoesCobrancaRemessa,
	validateLinhas,
]);

const validacoes = [...validacoesFieldsTabInformacao, validateQuandoEmitindoBoletos];

export default validacoes;
