import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import EditaContaBancariaSwitch from '../../commons/EditaContaBancariaSwitch';

interface CustomTheme extends Theme {
	orange: {
		[key: number]: string;
	};
}

const ImprimirPixField = () => {
	const useStyles = makeStyles((theme: CustomTheme) => ({ font_orange: { color: theme.orange[300] } }));
	const classes = useStyles();
	return (
		<Box>
			<EditaContaBancariaSwitch
				source='permite_gerar_pix'
				name='permite_gerar_pix'
				id='permite_gerar_pix'
				label='Imprimir Qrcode no boleto'
				translateChoice={false}
			/>
			<Typography className={classes.font_orange}>
				Ao habilitar esta opção, certifique-se de que o serviço <strong>API PIX</strong> esteja habilitado no
				seu cadastro do Sicredi.
			</Typography>
		</Box>
	);
};

export default ImprimirPixField;
