import { IGetValidacoesDescontoProps, TFunctionValidate } from '../../../types';

import {
	validacoesFieldsTabInformacao,
	createValidateLinhas,
	validateTipoIntegracao,
	createValidateDadosIntegracaoAPI,
	validateRequiredField,
	validateEspecieTitulo,
} from '../../../utils/validadoes';

import {
	getValidateQuandoEmitindoBoletos,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidacoesLimitePagamento,
	mensagensErrosPadroes,
	getValidacoesProtesto,
} from '../../default/ContaCorrenteProps';

const validacoesMulta = getValidacoesMulta({
	dias: false,
});

const validacoesJuros = getValidacoesJuros({
	dias: false,
});

type TGetValidacoesDesconto = (props: IGetValidacoesDescontoProps) => ReturnType<typeof getValidacoesDesconto>;

const validacoesDesconto = (getValidacoesDesconto as TGetValidacoesDesconto)({
	tipo: true,
	valor: true,
	dias: false,
	minValor: 0.01,
	msgErroMinValor: 'O valor do desconto à ser aplicado não pode ser menor que 0.01',
});

const validacoesLimitePagamento = getValidacoesLimitePagamento({
	nomeCampoLegivel: 'limite de pagamento',
	valorIsensao: 'SDL',
	msgErroMinValue: mensagensErrosPadroes.minValueLimitePagamentoComSemLimiteComoOpcao(),
});

const validacoesProtesto = getValidacoesProtesto({
	msgErroVazio: mensagensErrosPadroes.vazioProtesto(),
	minValue: 0,
	msgErroMinValue: mensagensErrosPadroes.minValueProtesto(),
});

const validacoesInstrucoesCobrancaRemessa = [
	validacoesMulta.tipo,
	validacoesMulta.valor,

	validacoesJuros.tipo,
	validacoesJuros.valor,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,

	validacoesLimitePagamento.tipo,
	validacoesLimitePagamento.dias,

	validacoesProtesto.tipo,
	validacoesProtesto.dias,
];

const validateContaCorrente: TFunctionValidate = ({ values }) =>
	validateRequiredField({
		values,
		field: 'conta_corrente',
		msg: 'Para emitir boletos, é requerido o número da conta corrente',
	});

const validateContaCedente: TFunctionValidate = ({ values }) =>
	validateRequiredField({
		values,
		field: 'conta_cedente',
		msg: 'Para emitir boletos, é requerido o número da conta cedente',
	});

const validateDadosIntegracaoAPI = createValidateDadosIntegracaoAPI();

const validateLinhas = createValidateLinhas({ numeroLinhas: 5, maxValue: 40 });

const validateQuandoEmitindoBoletos = getValidateQuandoEmitindoBoletos([
	validateContaCorrente,
	validateContaCedente,
	validateEspecieTitulo,
	validateTipoIntegracao,
	validateDadosIntegracaoAPI,
	...validacoesInstrucoesCobrancaRemessa,
	validateLinhas,
]);

const validacoes = [...validacoesFieldsTabInformacao, validateQuandoEmitindoBoletos];

export default validacoes;
