import React from 'react';

import { Box } from '@material-ui/core';

import CadastrarDadosIntegracaoAPIField from '../../../../../commons/CadastrarDadosIntegracaoAPIField';

import ContaCedenteField from '../../../../../tabs/TabConta/ContaCedenteField';
import AgenciaField from '../../../../../tabs/TabConta/AgenciaField';
import ImprimirPixField from '../../../../../tabs/TabConta/ImprimirPixField';

const TabConta = () => (
	<Box>
		<Box>
			<Box flexDirection='row' display='flex'>
				<AgenciaField />
				<ContaCedenteField />
			</Box>
			<Box>
				<ImprimirPixField />
			</Box>
			<Box gridColumnGap='0.5rem' display='flex' alignSelf='baseline' marginTop='6px' marginBottom='4px'>
				<CadastrarDadosIntegracaoAPIField />
			</Box>
		</Box>
	</Box>
);

export default TabConta;
