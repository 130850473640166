import React, { useCallback, useContext, useEffect, useState, createContext, useMemo } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { format, parse } from 'date-fns';

import { dictFunctionsVencimentoPrevisto } from './ajusteDomingosFeriados';
import decimalAdjust from '../../common/decimalAdjust';
import { ListContasPagarContext } from './ListContasPagarContext';

const TODAY = new Date();
TODAY.setHours(10, 0, 0);

const decimalAdjustFloor = (value, roundDigitNumber = -2, type = 'floor') =>
	decimalAdjust(type, value, roundDigitNumber);

export const LancamentoContaPagarContext = createContext();

export const LancamentoContaPagarContextProvider = ({ children, context, record = {} }) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const { lastCondominioId, setLastCondominioId } = useContext(ListContasPagarContext);
	const { setModalValue } = useContext(context);
	const [fornecedoresData, setFornecedoresData] = useState([]);
	const [fornecedor, setFornecedor] = useState(null);
	const [condominiosData, setCondominiosData] = useState([]);
	const [selectedCondominio, setSelectedCondominio] = useState(null);
	const [contasBancariasData, setContasBancariasData] = useState([]);
	const [selectedContaBancaria, setSelectedContaBancaria] = useState(null);
	const [dataVencimento, setDataVencimento] = useState(
		record?.data_vencimento_base
			? format(parse(record.data_vencimento_base, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
			: format(TODAY, 'dd/MM/yyyy')
	);
	const [valorTotalBruto, setValorTotalBruto] = useState(record?.valor_total_bruto || 0);
	const [valorTotal, setValorTotal] = useState(record.valor_total || 0);

	const [anexoNF, setAnexoNF] = useState(record.anexo_nf);
	const [anexoOrdemCompraServico, setAnexoOrdemCompraServico] = useState(record.anexo_ordem_compra_servico);

	const [despesas, setDespesas] = useState(record?.despesas || []);

	const [parcelas, setParcelas] = useState(
		record?.parcelas || [
			{
				valor: 0,
				valor_pendente: 0,
				valor_total: 0,
				data_vencimento_base: format(TODAY, 'yyyy-MM-dd'),
				data_vencimento: format(dictFunctionsVencimentoPrevisto['M'](TODAY), 'yyyy-MM-dd'),
				numero_parcela: 1,
				situacao: 'P',
				porcentagem: 100,
				editada: false,
				linha_digitavel: '',
			},
		]
	);

	const [parcelasPagas] = useState((record?.parcelas || []).filter((p) => p.situacao !== 'P'));
	const [valorPago] = useState(
		(record?.parcelas || [])
			.filter((p) => p.situacao !== 'P')
			.reduce((total, p) => decimalAdjustFloor(total + p.valor_pendente), 0)
	);

	const [modificadorVencimento, setModificadorVencimento] = useState(record?.modificador_vencimento || 'M');
	const [tipoParcelamento, setTipoParcelamento] = useState(record?.tipo_parcelamento || 'M');
	const [dadosCalculoVencimento, setDadosCalculoVencimento] = useState({
		dia: record?.vencimento_em || TODAY.getDate(),
		dia_semana: record?.vencimento_em || 1,
		dias: record?.vencimento_em || 1,
	});
	const [quantidadeParcelas, setQuantidadeParcelas] = useState(record?.quantidade_parcelas || 1);

	const [dataEmissao, setDataEmissao] = useState(
		record?.emissao
			? format(parse(record.emissao, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
			: format(TODAY, 'dd/MM/yyyy')
	);
	const [nf, setNf] = useState(record?.nf || '');
	const [observacoes, setObservacoes] = useState(record?.observacoes || '');

	const [erros, setErros] = useState({
		condominio: 'Selecione um condomínio',
		fornecedor: 'Selecione um fornecedor',
		conta_bancaria: 'Selecione uma conta bancária',
		despesas: 'Inclua ao menos uma despesa',
	});

	const closeModal = useCallback(() => {
		setModalValue((v) => ({ ...v, open: false }));
	}, [setModalValue]);

	const dP = useDataProvider();

	const handleSave = useCallback(
		(loading, setLoading) => {
			if (erros && Object.values(erros).length) {
				notify(Object.values(erros)[0], 'warning');
			} else {
				if (loading) return;
				setLoading(true);
				const get_anexo = (anexo) => (anexo ? (anexo.includes(';base64,') ? anexo : '') : null);
				const dadosSave = {
					fornecedor: fornecedor?.id,
					condominio: selectedCondominio?.id,
					data_vencimento_base: format(parse(dataVencimento, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
					valor_total_bruto: valorTotalBruto,
					valor_total: valorTotal,
					emissao: format(parse(dataEmissao, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
					nf,
					conta_bancaria: selectedContaBancaria?.id,
					observacoes,
					write_only_anexo_ordem_compra_servico: get_anexo(anexoOrdemCompraServico),
					write_only_anexo_nf: get_anexo(anexoNF),
					despesas: despesas.map(({ anexo_ordem_compra_servico, anexo_nf, ...d }) => ({
						...d,
						condominio: d.condominio,
						grupo: d.grupo?.id,
						conta_despesa: d.conta_despesa?.id,
						tipo_lancamento: d.tipo_lancamento?.id,
						valor_total: d.valor_total ? Number(d.valor_total.toFixed(2)) : 0,
					})),
					modificador_vencimento: modificadorVencimento,
					tipo_parcelamento: tipoParcelamento,
					quantidade_parcelas: quantidadeParcelas,
					vencimento_em: dadosCalculoVencimento[{ M: 'dia', S: 'dia_semana', E: 'dias' }[tipoParcelamento]],
					parcelas: parcelas.map(({ anexo_boleto, anexo_comprovante_pagamento, ...parcela }) => ({
						...parcela,
						valor_total: valorTotal,
						linha_digitavel: parcela?.linha_digitavel || '',
						write_only_anexo_boleto: get_anexo(anexo_boleto),
						write_only_anexo_comprovante_pagamento: get_anexo(anexo_comprovante_pagamento),
					})),
				};
				(record?.id
					? dP.update('contaapagar', {
							id: record?.id,
							data: dadosSave,
					  })
					: dP.create('contaapagar', {
							data: dadosSave,
					  })
				)
					.then(() => {
						const msgSucesso = record?.id
							? 'Conta a pagar alterada com sucesso'
							: 'Conta a pagar cadastrada com sucesso';
						setLoading(false);
						notify(msgSucesso);
						refresh();
						closeModal();
					})
					.catch((e) => {
						if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
						setLoading(false);
						const data = e?.response?.data || {};
						const { parcelas, despesas, ...rest } = data;
						const erro_parcela = (parcelas || [])[0];
						const erro_despesa = (despesas || [])[0];
						notify(
							Object.values(erro_parcela || erro_despesa || rest || {})[0] || [
								'Erro inesperado, tente recarregar a página',
							],
							'warning'
						);
					});
			}
		},
		[
			fornecedor,
			selectedCondominio,
			dataVencimento,
			valorTotalBruto,
			valorTotal,
			dataEmissao,
			nf,
			selectedContaBancaria,
			observacoes,
			despesas,
			modificadorVencimento,
			tipoParcelamento,
			quantidadeParcelas,
			dadosCalculoVencimento,
			parcelas,
			erros,
			notify,
			refresh,
			dP,
			closeModal,
			anexoNF,
			anexoOrdemCompraServico,
			record.id,
		]
	);
	const init = () => {
		dP.getList('condominios', {
			pagination: { perPage: 1000, page: 1 },
			sort: { field: 'id', order: 'ASC' },
			filter: { situacao: 'A' },
		}).then((response) => {
			const data = response?.data;
			if (data?.length) {
				const condominio_id = record?.condominio || lastCondominioId;
				const condominio = data.find((c) => c.id === condominio_id) || (data[0]?.id ? data[0] : null);
				setSelectedCondominio(condominio);
				setCondominiosData(data);
				if (condominio?.id !== lastCondominioId) setLastCondominioId(condominio.id);
			}
		});
		dP.getList('fornecedores', {
			pagination: { perPage: 1000, page: 1 },
			sort: { field: 'id', order: 'ASC' },
			filter: { situacao: 'A' },
		}).then((response) => {
			const data = response?.data;
			if (data?.length) {
				setFornecedor(data.find((f) => f.id === record?.fornecedor) || data[0]);
				setFornecedoresData(data);
			}
		});
	};

	const updateContaBancariasData = () => {
		if (selectedCondominio?.id) {
			dP.getList('conta_condominio', {
				pagination: { perPage: 1000, page: 1 },
				sort: { field: 'id', order: 'ASC' },
				filter: { situacao: 'A', id_condominio: selectedCondominio?.id },
			})
				.catch((e) => {
					if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
					return {};
				})
				.then((response) => {
					const data = response?.data;
					if (data && data.length && data[0]?.id) {
						setSelectedContaBancaria(data.find((cb) => cb.id === record?.conta_bancaria) || data[0]);
						setContasBancariasData(data);
					} else {
						selectedContaBancaria(null);
						setContasBancariasData([]);
					}
				});
		} else {
			setSelectedContaBancaria(null);
			setContasBancariasData([]);
		}
	};

	const updateValorTotal = () => {
		setValorTotalBruto(
			decimalAdjust(
				'round',
				despesas.reduce((valor_total_bruto, despesa) => (valor_total_bruto += despesa.valor_total_bruto), 0),
				-2
			)
		);
		setValorTotal(
			decimalAdjust(
				'round',
				despesas.reduce((total, despesa) => total + despesa.valor_total, 0),
				-2
			)
		);
	};

	useEffect(init, [dP]);
	useEffect(updateContaBancariasData, [selectedCondominio]);
	useEffect(updateValorTotal, [despesas]);

	const LancamentoContaPagarProviderValue = useMemo(
		() => ({
			record,
			condominiosData,
			selectedCondominio,
			setSelectedCondominio,
			fornecedoresData,
			fornecedor,
			setFornecedor,
			dataVencimento,
			setDataVencimento,
			valorTotalBruto,
			valorTotal,

			despesas,
			setDespesas,

			parcelasPagas,
			valorPago,
			parcelas,
			setParcelas,
			modificadorVencimento,
			setModificadorVencimento,
			tipoParcelamento,
			setTipoParcelamento,
			dadosCalculoVencimento,
			setDadosCalculoVencimento,
			quantidadeParcelas,
			setQuantidadeParcelas,

			dataEmissao,
			setDataEmissao,
			nf,
			setNf,
			contasBancariasData,
			setContasBancariasData,
			selectedContaBancaria,
			setSelectedContaBancaria,

			observacoes,
			setObservacoes,

			erros,
			setErros,
			handleSave,
			closeModal,
			anexoNF,
			setAnexoNF,
			anexoOrdemCompraServico,
			setAnexoOrdemCompraServico,
		}),
		[
			record,
			condominiosData,
			selectedCondominio,
			setSelectedCondominio,
			fornecedoresData,
			fornecedor,
			setFornecedor,
			dataVencimento,
			setDataVencimento,
			valorTotalBruto,
			valorTotal,

			despesas,
			setDespesas,

			parcelasPagas,
			valorPago,
			parcelas,
			setParcelas,
			modificadorVencimento,
			setModificadorVencimento,
			tipoParcelamento,
			setTipoParcelamento,
			dadosCalculoVencimento,
			setDadosCalculoVencimento,
			quantidadeParcelas,
			setQuantidadeParcelas,

			dataEmissao,
			setDataEmissao,
			nf,
			setNf,
			contasBancariasData,
			setContasBancariasData,
			selectedContaBancaria,
			setSelectedContaBancaria,

			observacoes,
			setObservacoes,

			erros,
			setErros,
			handleSave,
			closeModal,
			anexoNF,
			setAnexoNF,
			anexoOrdemCompraServico,
			setAnexoOrdemCompraServico,
		]
	);

	return (
		<LancamentoContaPagarContext.Provider value={LancamentoContaPagarProviderValue}>
			{children}
		</LancamentoContaPagarContext.Provider>
	);
};
