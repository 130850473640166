import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useNotify } from 'react-admin';

import { format, isBefore, parse } from 'date-fns';

import decimalAdjust from '../../../common/decimalAdjust';
import { LancamentoContaPagarContext } from '../LancamentoContaPagarContext';

import { ListParcelasContext } from './ListParcelasContext';

const decimalAdjustRound = (value, roundDigitNumber = -2, type = 'round') =>
	decimalAdjust(type, value, roundDigitNumber);
const parseISOSimples = (string) => parse(string, 'yyyy-MM-dd', new Date());

export const FormEditarParcelaContext = createContext();

export const FormEditarParcelaContextProvider = ({ children, context, record }) => {
	const { setModalValue } = useContext(context || { setModalValue: () => {} });
	const notify = useNotify();
	const { parcelas, setParcelas, valorTotal } = useContext(LancamentoContaPagarContext);
	const { recalcularParcelas } = useContext(ListParcelasContext);
	const [vencimento, setVencimento] = useState(
		format(parse(record.data_vencimento, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
	);
	const [vencimentoBase, setVencimentoBase] = useState(
		format(parse(record.data_vencimento_base, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
	);
	const [valorPendente, setValorPendente] = useState(record.valor_pendente);
	const [valorPorcentagem, setValorPorcentagem] = useState(record.porcentagem);
	const [valorDisponivel] = useState(
		decimalAdjustRound(
			parcelas
				.filter((p) => p.numero_parcela === record.numero_parcela || (p.situacao === 'P' && !p.editada))
				.reduce((total, parcela) => decimalAdjustRound(total + parcela.valor_pendente), 0)
		)
	);
	const [anexoBoleto, setAnexoBoleto] = useState(record.anexo_boleto || '');
	const [anexoComprovante, setAnexoComprovante] = useState(record.anexo_comprovante_pagamento || '');
	const [erros, setErros] = useState({});
	const [linhaOuCodigo, setLinhaOuCodigo] = React.useState(record.linha_digitavel || '');

	const closeModal = useCallback(() => {
		setModalValue((v) => ({ ...v, open: false }));
	}, [setModalValue]);

	const handleSave = useCallback(() => {
		if (erros && Object.values(erros).length) {
			notify(Object.values(erros)[0], 'warning');
		} else {
			new Promise((resolve) => {
				const parcelasRecalculadas = parcelas
					.map((p) =>
						p.numero_parcela !== record.numero_parcela
							? p
							: {
									...p,
									data_vencimento_base: format(
										parse(vencimentoBase, 'dd/MM/yyyy', new Date()),
										'yyyy-MM-dd'
									),
									data_vencimento: format(parse(vencimento, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
									valor_pendente: valorPendente,
									porcentagem: valorPorcentagem,
									anexo_boleto: anexoBoleto,
									anexo_comprovante_pagamento: anexoComprovante,
									editada: true,
									linha_digitavel: linhaOuCodigo ? linhaOuCodigo.trim() : '',
							  }
					)
					.sort((PA, PP) => {
						const PAISO = parseISOSimples(PA.data_vencimento);
						const PPISO = parseISOSimples(PP.data_vencimento);
						if (isBefore(PAISO, PPISO)) return -1;
						if (isBefore(PPISO, PAISO)) return 1;
						if (PAISO.numero_parcela < PPISO.numero_parcela) return -1;
						if (PAISO.numero_parcela > PPISO.numero_parcela) return 1;
						return 0;
					})
					.map((p, i) => {
						p.numero_parcela = i + 1;
						return p;
					});
				setParcelas(parcelasRecalculadas);
				resolve(parcelasRecalculadas);
			})
				.then((parcelasRecalculadas) => {
					setParcelas(recalcularParcelas(parcelasRecalculadas));
					notify('Parcela alterada com sucesso');
				})
				.catch(() => {})
				.finally(() => {
					setModalValue((v) => ({ ...v, open: false }));
				});
		}
	}, [
		parcelas,
		record.numero_parcela,
		recalcularParcelas,
		vencimento,
		vencimentoBase,
		valorPendente,
		valorPorcentagem,
		setParcelas,
		erros,
		notify,
		setModalValue,
		anexoBoleto,
		anexoComprovante,
		linhaOuCodigo,
	]);

	useEffect(() => {
		setValorPorcentagem(decimalAdjust('round', (valorPendente / valorTotal) * 100, -2));
	}, [setValorPorcentagem, valorTotal, valorPendente]);

	const FormEditarParcelaProviderValue = useMemo(
		() => ({
			context,
			vencimento,
			setVencimento,
			vencimentoBase,
			setVencimentoBase,
			valorPendente,
			setValorPendente,
			valorPorcentagem,
			valorDisponivel,
			erros,
			setErros,
			closeModal,
			setAnexoBoleto,
			anexoBoleto,
			setAnexoComprovante,
			anexoComprovante,
			handleSave,
			setLinhaOuCodigo,
			linhaOuCodigo,
		}),
		[
			context,
			vencimento,
			setVencimento,
			vencimentoBase,
			setVencimentoBase,
			valorPendente,
			setValorPendente,
			valorPorcentagem,
			valorDisponivel,
			erros,
			setErros,
			closeModal,
			setAnexoBoleto,
			anexoBoleto,
			setAnexoComprovante,
			anexoComprovante,
			handleSave,
			setLinhaOuCodigo,
			linhaOuCodigo,
		]
	);

	return (
		<FormEditarParcelaContext.Provider value={FormEditarParcelaProviderValue}>
			{children}
		</FormEditarParcelaContext.Provider>
	);
};
