import React, { useEffect } from 'react';
import { TextInput, useDataProvider, useNotify } from 'react-admin';
import { Typography, TextField, Box, Checkbox } from '@material-ui/core';
import { CustomDialogBodySizeEditable } from 'components/common/ModalSizeEditableContext';
import ToolInputSelect, { ToolInput } from 'components/common/commonComponentsTSX/inputs/Toolinput';
import { RawKBDatePicker } from 'components/common/InputDate';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { parse } from 'date-fns';

import { TFuncao, TFuncionario, TFuncionarioEditado } from '../types';

import { FuncaoContext } from '../contexts/FuncoesContext';
import dataProvider from 'dataProvider';
import { FuncionarioContext } from '../contexts/FuncionarioContext';
import { PessoasContext } from 'context/PessoaContextProvider';
import { CondominiosContext } from 'context/CondominioContextProvider';

export const ModalEditaFuncionario: React.FC<TFuncionario> = (funcionario: TFuncionario) => {
	const { pessoas, pessoaSelecionada, setPessoaSelecionada } = React.useContext(PessoasContext);
	const { funcionarios, setFuncionarios } = React.useContext(FuncionarioContext);
	const [funcionarioEditado, setFuncionarioEditado] = React.useState<TFuncionarioEditado>({} as TFuncionarioEditado);
	const { condominioSelecionado } = React.useContext(CondominiosContext);
	const { funcao, setFuncao } = React.useContext(FuncaoContext);
	const [loading, setLoading] = React.useState(false);
	const notify = useNotify();

	const handleSetFuncaoId = (funcao: TFuncao) => {
		if (funcao?.id) setFuncao(funcao);
	};

	useEffect(() => {
		if (funcionario) {
			setFuncionarioEditado({
				...funcionario,
				dataEntrada: parse(funcionario.dataEntrada, 'yyyy-MM-dd', new Date()),
				dataSaida: funcionario.dataSaida ? parse(funcionario.dataSaida, 'yyyy-MM-dd', new Date()) : undefined,
			});
		}
	}, [funcionario]);

	const handleEditaFuncionario = () => {
		if (loading) return;
		setLoading(true);
		dataProvider
			.update('funcionarios', {
				id: funcionarioEditado.id,
				data: {
					condominio: condominioSelecionado?.id,
					pessoa: funcionarioEditado.pessoa.id,
					acesso_app: funcionarioEditado.acessoApp,
					funcao: funcao.id,
					data_entrada: funcionarioEditado.dataEntrada.toISOString().split('T')[0],
					data_saida: funcionarioEditado.dataSaida
						? funcionarioEditado.dataSaida.toISOString().split('T')[0]
						: null,
				},
			})
			.then((res) => {
				notify('Cadastro atualizado com sucesso', { type: 'success' });
				const dados: TFuncionario = {
					id: res.data.id,
					pessoa: funcionarioEditado.pessoa,
					dataEntrada: res.data.data_entrada,
					dataSaida: res.data.data_saida ? res.data.data_saida : null,
					acessoApp: res.data.acesso_app,
					funcao: funcao,
				};
				setFuncionarios((prev: TFuncionario[]) => {
					const index = prev.findIndex((f) => f.id === res.data.id);
					prev[index] = dados;
					return [...prev];
				});

				setFuncao(null);

				setLoading(false);
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Ocorreu um problema ao realizar o cadastro.', { type: 'error' });
			});
	};

	return (
		<>
			<CustomDialogBodySizeEditable
				title={'Editar Funcionário'}
				closeInSubmit={true}
				DialogContentCustomStyle={{ overflow: 'hidden' }}
				form={{
					valid:
						funcao?.id &&
						funcionarioEditado.dataEntrada &&
						(funcionarioEditado.dataSaida
							? funcionarioEditado.dataEntrada < funcionarioEditado.dataSaida
							: true),
					handleSubmit: handleEditaFuncionario,
					component: (
						<>
							<Box paddingBottom='5px'>
								<Autocomplete
									value={funcionarioEditado.pessoa || null}
									renderInput={(params) => (
										<TextField {...params} fullWidth label='Selecionar Pessoa' variant='outlined' />
									)}
									size='small'
									options={pessoas}
									getOptionLabel={(option) => option.nome}
									onChange={(e, value) => {
										if (value) {
											setPessoaSelecionada(value);
											setFuncionarioEditado((prev) => ({ ...prev, pessoa: value }));
										}
									}}
								/>
							</Box>
							<Box display='flex' width='100%' paddingBottom='5px'>
								<Box width='100%'>
									<RawKBDatePicker
										fullWidth
										format='dd/MM/yyyy'
										margin='dense'
										variant='inline'
										autoOk
										label='Data de Entrada*'
										value={new Date(funcionarioEditado.dataEntrada)}
										onChange={(date: Date) =>
											setFuncionarioEditado((prev) => ({
												...funcionarioEditado,
												dataEntrada: date,
											}))
										}
									/>
								</Box>
								<Box paddingLeft='10px' width='100%'>
									<RawKBDatePicker
										fullWidth
										format='dd/MM/yyyy'
										margin='dense'
										variant='inline'
										label='Data de Saída'
										value={funcionarioEditado.dataSaida ? funcionarioEditado.dataSaida : null}
										onChange={(date: Date) =>
											setFuncionarioEditado((prev) => ({ ...prev, dataSaida: date }))
										}
									/>
								</Box>
							</Box>
							<Box display='flex'>
								<Box display='flex' width='100%' paddingRight='5px'>
									<ToolInputSelect
										ToolInput={
											<ToolInput
												getOptionsSelected={handleSetFuncaoId}
												optionText='descricao'
												label='função'
												TextFieldProps={{ fullWidth: true, size: 'small' }}
												defaultValueId={funcionarioEditado.funcao?.id}
											></ToolInput>
										}
										optionText='descricao'
										source='funcoes_funcionarios'
									/>
								</Box>
								<Box width='100%' display='flex' alignItems='center'>
									<Checkbox
										checked={Boolean(funcionarioEditado.acessoApp)}
										onClick={() => {
											setFuncionarioEditado((prev) => ({ ...prev, acessoApp: !prev.acessoApp }));
										}}
									/>
									<Typography>Acesso ao App</Typography>
								</Box>
							</Box>
						</>
					),
				}}
				customActions={null}
			/>
		</>
	);
};
