import React, { useContext, useEffect, useState, memo, useRef } from 'react';

import {
	List,
	ListBase,
	Pagination,
	useDataProvider,
	useListContext,
	useNotify,
	useUpdate,
	TextField as TxtField,
	Title,
} from 'react-admin';

import {
	Box,
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	TextField,
	Button,
	FormControlLabel,
	Switch,
	useTheme,
	makeStyles,
	TableSortLabel,
	Checkbox,
} from '@material-ui/core';

import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import SearchIcon from '@material-ui/icons/Search';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import EditIcon from '@material-ui/icons/Edit';
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';

import { sub, format, parseISO, isAfter, isBefore, isEqual } from 'date-fns';

import { TooltipIconButton } from '../../common/TooltipIconButton';
import { Tabela } from '../../common/Tabela';
import { MovimentacoesContext, MovimentacoesContextProvider } from './MovimentacoesContext';
import { NumberFormatBRL } from '../../common/CurrencyInput';
import { tipoConta } from '../../../fieldControl/tipoContaBancaria';
import { CustomDialog, CustomDialogBody, ModalContext, ModalContextProvider } from '../../common/ModalContext';
import { ModalSizeEditableContextProvider } from '../../common/ModalSizeEditableContext';
import { FormularioLancamento, FormularioTransferencia, FormularioSaldoInicial } from './modais';
import ButtonExcluirMovimentacoesLote from './ButtonExcluirMovimentacoesLote';
import TooltipImportarExtratoBancarioButton from './components/fluxoImportacaoExtratoBancario/common/TooltipImportarExtratoBancarioButton';

import { Situacao } from './Situacao';
import { ButtonClose } from '../../common/buttons/ButtonClose';
import decimalAdjust from '../../common/decimalAdjust';
import PeriodoSelect, { periodoOptions } from '../../common/PeriodoSelect';
import '../../common/css/datePickerFix.css';
import ValorDisplayField from '../../common/ValorDisplayField';
import ModalDetalhamentosLancamentosDespesaArrecadacao from './components/ModalDetalhamentosLancamentosDespesaArrecadacao';

export const useRemoveButtonStyle = makeStyles((theme) => ({
	removeButton: {
		color: theme.palette.syndikosRed.contrastText,
		backgroundColor: theme.palette.syndikosRed.main,
		'&:hover': {
			backgroundColor: theme.palette.syndikosRed.dark,
		},
	},
}));

const useStylesGridTemplate = makeStyles(() => ({
	container: {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)',
		gridTemplateRows: 'minmax(max-content, 115px) minmax(min-content, 115px) minmax(0px, 1fr)',
		paddingTop: '10px',
		gap: '18px',
	},
	condominioSelect: {
		gridRow: '1',
		gridColumn: '1/2',
	},
	periodoSelect: {
		gridRow: '1',
		gridColumn: '2/3',
	},
	contaSelect: {
		gridRow: '1/3',
		gridColumn: '3/4',
		height: '100%',
	},
	lancamentos: {
		gridRow: '2/3',
		gridColumn: '1/2',
		display: 'flex',
		padding: '0 2rem',
		'& > * > button': {
			padding: '3px 16px',
		},
	},
	movimentacoes: {
		gridRow: '3/4',
		gridColumn: '1/4',
	},
	totalizadores: {
		gridRow: '2/3',
		gridColumn: '2/3',
		display: 'flex',
	},
}));

const useStylesTotalizadores = makeStyles(() => ({
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		alignSelf: 'center',
		padding: '0 15px',
		'& > *:first-child': {
			marginBottom: '6px',
		},
		'& > *:last-child': {
			marginTop: '6px',
		},
		'& > *': {
			display: 'grid',
			gridTemplateColumns: '50px 1fr 1fr',
			alignItems: 'center',
			columnGap: '40px',
			textAlign: 'left',
			fontFamily: 'Roboto, sans-serif',
		},
		'& > * > svg': {
			fontSize: '35px',
		},
		'& > * > *:last-child': {
			textAlign: 'right',
		},
	},
}));

const formatDate = (date) => {
	if (!date || date.toString() === 'Invalid Date') return '';
	return format(date, 'dd/MM/yyyy');
};

const CurrencyFormat = ({ value }) => (
	<NumberFormatBRL
		displayType='text'
		value={value}
		fixedDecimalScale
		onChange={() => {}}
		decimalScale={2}
		prefix={value < 0 ? '-' : ''}
	/>
);

const formatOrigem = (origem) => {
	switch (origem) {
		case 'M':
			return 'Manual';
		case 'TS':
			return 'Transferência de Saldo';
		case 'BM':
			return 'Contas a receber - Baixa manual';
		case 'BA':
			return 'Contas a receber - Arquivo de retorno (Automática)';
		case 'BC':
			return 'Contas a Pagar';
		case 'EB':
			return 'Extrato Bancário';
		default:
			return origem;
	}
};

const formatTipo = (tipo) => {
	switch (tipo) {
		case 'C':
			return 'Condomínio';
		case 'U':
			return 'Unidade';
		case 'G':
			return 'Grupo';
		default:
			return tipo;
	}
};

const AcoesMovimentacao = ({ situacao, tipo_movimentacao, id, is_saldo_inicial, origem, ids, ...rest }) => {
	const { setModalValue } = useContext(ModalContext);
	const { setData } = useContext(MovimentacoesContext);
	const notify = useNotify();
	const dp = useDataProvider();

	let acoes = [];

	let endpointUpdate = '';
	switch (tipo_movimentacao) {
		case 'LR':
			endpointUpdate = 'lancamento_receita';
			break;
		case 'LD':
			endpointUpdate = 'lancamento_despesa';
			break;
		case 'TF':
			endpointUpdate = 'transferencia';
			break;
		case 'RM':
			endpointUpdate = 'recebimento_manual';
			break;
		case 'BM':
			endpointUpdate = 'parcela';
			break;
		default:
			break;
	}

	const [update] = useUpdate(endpointUpdate, id);

	const alterarSituacao = () => {
		update();
		notify('Situação alterada com sucesso');
		setData((d) =>
			d.map((mov) => {
				if (mov.id === id) {
					let newSituacao = mov.situacao === 'N' ? 'C' : 'N';
					return { ...mov, situacao: newSituacao };
				}
				return mov;
			})
		);
	};

	const visualizarDetalhamentos = (
		<TooltipIconButton
			style={{ padding: 2 }}
			title='Visualizar as contas de despesas'
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody: <ModalDetalhamentosLancamentosDespesaArrecadacao id={id} />,
				}));
			}}
		>
			<SearchIcon style={{ width: 20, height: 20 }} />
		</TooltipIconButton>
	);

	const visualizar = (
		<TooltipIconButton
			style={{ padding: 2 }}
			title='Visualizar'
			onClick={() => {
				if (tipo_movimentacao === 'BM') {
					Promise.allSettled([
						dp
							.getList('parcela_despesa', {
								pagination: { page: 1, perPage: 10000 },
								sort: {
									field: 'tipo_conta_despesa',
									order: 'ASC',
								},
								filter: {
									conta_a_pagar_parcela: id,
								},
							})
							.then((response) => (response?.data || []).filter((d) => d.id !== 0))
							.catch((e) => ([401, 403].includes(e?.response?.status) ? Promise.reject(e) : [])),
						dp
							.getList('lancamento_baixa', {
								pagination: { page: 1, perPage: 10000 },
								sort: {},
								filter: {
									conta_a_pagar_parcela: id,
									detalhamento_conta_a_pagar_parcela: true,
								},
							})
							.then((response) => (response?.data || []).filter((d) => d.id !== 0))
							.catch((e) => ([401, 403].includes(e?.response?.status) ? Promise.reject(e) : [])),
						dp
							.getSimple('fornecedores', {
								pagination: { page: 1, perPage: 1 },
								filter: { parcela_conta_a_pagar: id },
							})
							.then((response) => ((response?.data || {}).results || [])[0])
							.catch((e) => ([401, 403].includes(e?.response?.status) ? Promise.reject(e) : undefined)),
						dp
							.getSimple('parcela', { id: id })
							.then((response) => response?.data || {})
							.catch((e) => ([401, 403].includes(e?.response?.status) ? Promise.reject(e) : {})),
					]).then((responses) => {
						const firstDeloggedResponse = responses.find((r) => r.status === 'rejected');
						if (firstDeloggedResponse) return Promise.reject(firstDeloggedResponse.reason);
						const baixaLancamentosDespesa = responses[0].value;
						const lancamentosBaixa = responses[1].value;
						const fornecedor = responses[2]?.value?.nome || '';
						const parcela = responses[3]?.value;
						lancamentosBaixa.map((l) => {
							const lancamentoDespesaIndex = baixaLancamentosDespesa.findIndex((a) => a.id === l.id);
							if (lancamentoDespesaIndex < 0) {
								baixaLancamentosDespesa.push(l);
							} else {
								const arrecadacao = baixaLancamentosDespesa[lancamentoDespesaIndex];
								arrecadacao.valor = decimalAdjust('round', arrecadacao.valor + l.valor, -2);
								baixaLancamentosDespesa[lancamentoDespesaIndex] = arrecadacao;
							}
							return l;
						});
						if (baixaLancamentosDespesa.length) {
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: (
									<TabelaLancDespBaixaManual
										fornecedor={fornecedor}
										rows={baixaLancamentosDespesa.sort((a, b) => {
											const valorA = a.tipo_conta_despesa;
											const valorB = b.tipo_conta_despesa;
											if (valorA > valorB) return 1;
											if (valorA < valorB) return -1;
											return 0;
										})}
										parcela={parcela}
									/>
								),
							}));
						} else {
							notify(
								'Não foi possível coletar os lancamentos de despesa associados à essa conta',
								'warning'
							);
						}
					});
				} else {
					Promise.allSettled([
						dp
							.getSimple(`arrecadacao_unidade_paga/${id}/detalhe_conta_receber_paga`)
							.then((response) =>
								(response?.data || [])
									.map((d) => ({
										...d,
										id: d?.conta_id || 0,
										valor: d?.valor_somado,
									}))
									.filter((d) => d.id !== 0)
							)
							.catch((e) => ([401, 403].includes(e?.response?.status) ? Promise.reject(e) : [])),
						dp
							.getList('lancamento_baixa', {
								pagination: { page: 1, perPage: 10000 },
								sort: {},
								filter: {
									recebimento_manual: id,
									detalhamento_conta_receber: true,
								},
							})
							.then((response) => (response?.data || []).filter((d) => d.id !== 0))
							.catch((e) => ([401, 403].includes(e?.response?.status) ? Promise.reject(e) : [])),
						dp
							.getSimple('pessoas', {
								pagination: { page: 1, perPage: 1 },
								sort: { field: 'data_entrada', order: 'DESC' },
								filter: {
									recebimento_manual: id,
								},
							})
							.then((response) => ((response?.data || {}).results || [])[0])
							.catch((e) => ([401, 403].includes(e?.response?.status) ? Promise.reject(e) : undefined)),
					])
						.then((responses) => {
							const firstDeloggedResponse = responses.find((r) => r.status === 'rejected');
							if (firstDeloggedResponse) return Promise.reject(firstDeloggedResponse.reason);
							const arrecadacaoUnidades = responses[0].value;
							const lancamentosBaixa = responses[1].value;
							const nomeResponsavel = responses[2]?.value?.nome || '';
							lancamentosBaixa.map((l) => {
								const arrecadacaoIndex = arrecadacaoUnidades.findIndex((a) => a.id === l.id);
								if (arrecadacaoIndex < 0) {
									arrecadacaoUnidades.push(l);
								} else {
									const arrecadacao = arrecadacaoUnidades[arrecadacaoIndex];
									arrecadacao.valor = decimalAdjust('round', arrecadacao.valor + l.valor, -2);
									arrecadacaoUnidades[arrecadacaoIndex] = arrecadacao;
								}
								return l;
							});
							if (arrecadacaoUnidades.length) {
								setModalValue((v) => ({
									...v,
									open: true,
									dialogBody: (
										<TabelaArrecadacoesUnidades
											nomeResponsavel={nomeResponsavel}
											rows={arrecadacaoUnidades.sort((a, b) => {
												const valorA = a.tipo_conta;
												const valorB = b.tipo_conta;
												if (valorA > valorB) return 1;
												if (valorA < valorB) return -1;
												return 0;
											})}
										/>
									),
								}));
							} else {
								notify(
									'Não foi possível coletar as arrecadações de unidade associadas à essa conta a receber',
									'warning'
								);
							}
						})
						.catch((e) => {
							if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
							notify(
								'Não foi possível coletar as arrecadações de unidade associadas à essa conta a receber',
								'warning'
							);
						});
				}
			}}
		>
			<SearchIcon style={{ width: 20, height: 20 }} />
		</TooltipIconButton>
	);

	const editar = (
		<TooltipIconButton
			style={{ padding: 2 }}
			title='Editar'
			onClick={() => {
				const propsPadroes = { id, situacao, tipo_movimentacao, ...rest };
				const componentesEPropsPorTipoMovimentacao = {
					TF: { Componente: FormularioTransferencia, props: propsPadroes },
					SI: { Componente: FormularioSaldoInicial, props: propsPadroes },
					LR: {
						Componente: FormularioLancamento,
						props: { ...propsPadroes, operacao: { tipo: 'receita', acao: 'editar' } },
					},
					LD: {
						Componente: FormularioLancamento,
						props: { ...propsPadroes, operacao: { tipo: 'despesa', acao: 'editar' } },
					},
				};
				const { Componente, props } = componentesEPropsPorTipoMovimentacao[tipo_movimentacao] ?? {};
				if (Componente)
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: <Componente {...props} />,
					}));
			}}
		>
			<EditIcon style={{ width: 20, height: 20 }} />
		</TooltipIconButton>
	);

	const clonar = (
		<TooltipIconButton
			style={{ padding: 2 }}
			title='Copiar Lançamento'
			onClick={() => {
				setModalValue((v) => ({
					...v,
					open: true,
					dialogBody:
						tipo_movimentacao === 'TF' ? (
							<FormularioTransferencia
								{...{
									tipo_movimentacao,
									...rest,
								}}
								copia
							/>
						) : (
							<FormularioLancamento
								{...{
									id,
									tipo_movimentacao,
									...rest,
								}}
								operacao={{
									tipo: tipo_movimentacao === 'LD' ? 'despesa' : 'receita',
									acao: 'cadastrar',
								}}
								copia
							/>
						),
				}));
			}}
		>
			<FilterNoneOutlinedIcon style={{ width: 20, height: 20 }} />
		</TooltipIconButton>
	);

	const alterar = (
		<TooltipIconButton style={{ padding: 2 }} title='Alterar situação' onClick={alterarSituacao}>
			<AutorenewIcon style={{ width: 20, height: 20 }} />
		</TooltipIconButton>
	);

	const visualizar_previsto = (
		<TooltipIconButton
			style={{ padding: 2 }}
			title='Visualizar previstos'
			onClick={() => {
				if (tipo_movimentacao === 'RMP') {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: <ModalContasReceber ids={ids} />,
					}));
				} else {
					setModalValue((v) => ({
						...v,
						open: true,
						dialogBody: <ModalContasAPagar ids={ids} />,
					}));
				}
			}}
		>
			<SearchIcon style={{ width: 20, height: 20 }} />
		</TooltipIconButton>
	);

	switch (situacao) {
		case 'P':
			acoes = [visualizar_previsto];
			break;
		default:
			acoes = origem[0] !== 'B' ? [editar, clonar, alterar, visualizarDetalhamentos] : [alterar, visualizar];
			break;
	}
	return (
		<Box display='flex' justifyContent='flex-end'>
			{acoes}
		</Box>
	);
};

const TabelaMovimentacoes = memo(({ className }) => {
	const theme = useTheme();
	const {
		data,
		condominio,
		datas_after,
		datas_before,
		id_conta_bancaria,
		ocultar_previsoes,
		setOcultar_previsoes,
		idsSelecionados,
		setIdsSelecionados,
	} = useContext(MovimentacoesContext);

	const saldo = useRef(0);

	const setSaldo = () => {
		saldo.current = 0;
	};

	const handleSingleCheckChange = (e, idMovimentacao) => {
		e.target.checked
			? setIdsSelecionados((ids) => [idMovimentacao, ...ids])
			: setIdsSelecionados(idsSelecionados.filter((v) => v !== idMovimentacao));
	};

	useEffect(setSaldo, [data, condominio, datas_after, datas_before, id_conta_bancaria, ocultar_previsoes]);

	return (
		<Box className={className}>
			<Paper>
				<Box
					p='15px'
					display='flex'
					justifyContent='space-between'
					alignItems='center'
					style={{ position: 'relative', overflow: 'hidden' }}
				>
					<Paper
						style={{
							display: 'flex',
							backgroundColor: theme.fundo[100],
							height: '64px',
							alignItems: 'center',
							justifyContent: 'space-between',
							padding: '0 24px',
							position: 'absolute',
							left: '0',
							top: `${idsSelecionados.length ? 0 : 70}px`,
							transition: 'all .3s ease-in',
							width: '100%',
							zIndex: '1',
							boxShadow: 'none',
						}}
					>
						<Box display='flex'>
							<TooltipIconButton
								size='small'
								title='Desmarcar'
								style={{ marginRight: '1em' }}
								onClick={() => setIdsSelecionados([])}
							>
								<CloseIcon />
							</TooltipIconButton>
							<Typography variant='h6' color='primary'>
								{idsSelecionados.length} selecionados
							</Typography>
						</Box>
						<Box marginRight='4em'>
							<ButtonExcluirMovimentacoesLote ids={idsSelecionados} />
						</Box>
					</Paper>
					<Typography variant='h6'>Movimentações</Typography>
					<Box display='flex' justifyContent='end' alignItems='center' gridGap='1em'>
						<ModalSizeEditableContextProvider
							customDialogProps={{
								disableBackdropClick: true,
								disableEscapeKeyDown: true,
								minWidth: '95vw',
							}}
						>
							<TooltipImportarExtratoBancarioButton />
						</ModalSizeEditableContextProvider>
						<Tooltip title='Ocultar Previsões'>
							<FormControlLabel
								control={
									<Switch
										color='primary'
										checked={ocultar_previsoes}
										onClick={(e) => setOcultar_previsoes(e.target.checked)}
									/>
								}
							/>
						</Tooltip>
					</Box>
				</Box>
				<TableContainer
					component={Box}
					style={{
						position: 'relative',
						maxHeight: 'calc(80vh)',
						overflow: 'auto',
					}}
				>
					<Table stickyHeader>
						<TableHead>
							{data?.length ? (
								<TableCell style={{ background: '#fff' }}>
									<Checkbox
										checked={
											idsSelecionados.length &&
											data
												.filter((v) => ['M', 'TS', 'EB'].includes(v.origem))
												.map((v) => v.id)
												.every((v) => idsSelecionados.includes(v))
										}
										onChange={(e) => {
											e.target.checked
												? setIdsSelecionados(
														data
															.filter((v) => ['M', 'TS', 'EB'].includes(v.origem))
															.map((v) => v.id)
												  )
												: setIdsSelecionados([]);
										}}
									/>
								</TableCell>
							) : (
								<></>
							)}
							<TableCell style={{ background: '#fff' }} align='right'>
								Ações
							</TableCell>
							<TableCell style={{ background: '#fff' }}>Situação</TableCell>
							<TableCell style={{ background: '#fff' }}>Data Lançamento</TableCell>
							<TableCell style={{ background: '#fff' }}>Transação</TableCell>
							<TableCell style={{ background: '#fff' }}>Descrição</TableCell>
							<TableCell style={{ background: '#fff' }}>Tipo</TableCell>
							<TableCell style={{ background: '#fff' }} align='right'>
								Valor (R$)
							</TableCell>
							<TableCell style={{ background: '#fff' }} align='right'>
								Saldo (R$)
							</TableCell>
							<TableCell style={{ background: '#fff' }}>Origem</TableCell>
							<TableCell style={{ background: '#fff' }}>Última Atualização</TableCell>
						</TableHead>
						<TableBody>
							{data &&
							condominio &&
							datas_after &&
							datas_before &&
							id_conta_bancaria &&
							data[0] &&
							data[0]?.saldo_anterior ? (
								data
									.sort((a, b) => {
										if (a.id > 0) {
											if (isBefore(parseISO(a.data), parseISO(b.data))) {
												return -1;
											}
											if (isAfter(parseISO(a.data), parseISO(b.data))) {
												return 1;
											}
											if (isEqual(parseISO(a.data), parseISO(b.data))) {
												if (a.transacao < b.transacao) {
													return -1;
												}
												if (a.transacao > b.transacao) {
													return 1;
												}
											}
										}
										return 0;
									})
									.map((movimentacao, i) => {
										if (movimentacao.id < 1) {
											saldo.current = Number(movimentacao.saldo_anterior);
										} else {
											let valor = 0;
											if (movimentacao.conta_b_origem === id_conta_bancaria) {
												valor = -1 * Number(movimentacao.valor);
											} else if (movimentacao.conta_b_destino === id_conta_bancaria) {
												valor = Number(movimentacao.valor);
											}
											saldo.current = saldo.current + valor;
										}

										const row =
											movimentacao.id < 1 ? (
												<TableRow key={movimentacao.id} hover>
													<TableCell></TableCell>
													<TableCell></TableCell>
													<TableCell
														style={{
															fontWeight: 'bold',
														}}
													>
														{formatDate(
															sub(parseISO(datas_after), {
																days: 1,
															})
														)}
													</TableCell>
													<TableCell></TableCell>
													<TableCell
														style={{
															fontWeight: 'bold',
														}}
													>
														SALDO ANTERIOR
													</TableCell>
													<TableCell></TableCell>
													<TableCell></TableCell>
													<TableCell
														align='right'
														style={{
															fontWeight: 'bold',
															color:
																movimentacao.saldo_anterior < 0
																	? theme.palette.error.dark
																	: theme.blue[500],
														}}
													>
														<CurrencyFormat
															value={
																movimentacao &&
																movimentacao.saldo_anterior &&
																movimentacao.saldo_anterior.length > 0 &&
																movimentacao.saldo_anterior[0]
															}
														/>
													</TableCell>
													<TableCell></TableCell>
													<TableCell></TableCell>
												</TableRow>
											) : (
												<TableRow key={movimentacao.id} hover>
													{['M', 'TS', 'EB'].includes(movimentacao.origem) ? (
														<TableCell>
															<Checkbox
																checked={idsSelecionados.includes(movimentacao.id)}
																onChange={(e) =>
																	handleSingleCheckChange(e, movimentacao.id)
																}
															/>
														</TableCell>
													) : (
														<TableCell></TableCell>
													)}
													<TableCell align='right'>
														<AcoesMovimentacao {...movimentacao} />
													</TableCell>
													<TableCell>
														<Situacao situacao={movimentacao.situacao} />
													</TableCell>
													<TableCell>{formatDate(parseISO(movimentacao.data))}</TableCell>
													<TableCell>{movimentacao.transacao}</TableCell>
													<TableCell>{movimentacao.historico}</TableCell>
													<TableCell>{formatTipo(movimentacao.tipo_lancamento)}</TableCell>
													<TableCell
														align='right'
														style={{
															color:
																movimentacao.conta_b_origem === id_conta_bancaria
																	? theme.palette.error.dark
																	: theme.blue[500],
														}}
													>
														<CurrencyFormat
															value={
																movimentacao.conta_b_origem === id_conta_bancaria
																	? -Math.abs(movimentacao.valor)
																	: movimentacao.valor
															}
														/>
													</TableCell>
													<TableCell
														align='right'
														style={{
															color:
																saldo.current < 0
																	? theme.palette.error.dark
																	: theme.blue[500],
														}}
													>
														<CurrencyFormat value={saldo.current} />
													</TableCell>
													<TableCell>{formatOrigem(movimentacao.origem)}</TableCell>
													<TableCell>{formatDate(parseISO(movimentacao.alterado))}</TableCell>
												</TableRow>
											);

										let saldoRow = <></>;
										if (
											(i > 0 &&
												data[Object.keys(data)[i + 1]] &&
												isAfter(
													parseISO(data[Object.keys(data)[i + 1]].data),
													parseISO(movimentacao.data)
												)) ||
											i === Object.keys(data).length - 1
										) {
											saldoRow = (
												<TableRow key={`saldo-${movimentacao.id}`} hover>
													<TableCell></TableCell>
													<TableCell></TableCell>
													<TableCell
														style={{
															fontWeight: 'bold',
														}}
													>
														{formatDate(
															sub(parseISO(movimentacao.data || datas_before), {
																days: 0,
															})
														)}
													</TableCell>
													<TableCell></TableCell>
													<TableCell
														style={{
															fontWeight: 'bold',
														}}
													>
														SALDO
													</TableCell>
													<TableCell></TableCell>
													<TableCell></TableCell>
													<TableCell
														align='right'
														style={{
															fontWeight: 'bold',
															color:
																saldo.current < 0
																	? theme.palette.error.dark
																	: theme.blue[500],
														}}
													>
														<CurrencyFormat value={saldo.current} />
													</TableCell>
													<TableCell></TableCell>
													<TableCell></TableCell>
												</TableRow>
											);
										}
										return [row, saldoRow];
									})
							) : (
								<TableRow>
									<TableCell colSpan={10} align='center'>
										Nenhuma movimentação encontrada
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Box>
	);
});

const FilterBox = memo(({ className, titulo, children, boxProps = {}, paperStyle = {} }) => (
	<Paper
		className={className}
		{...boxProps}
		style={{
			padding: 15,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			...paperStyle,
		}}
	>
		<Typography variant='h6'>{titulo}</Typography>
		<div>{children}</div>
	</Paper>
));

const CondominioSelect = ({ className }) => {
	const { setCondominio, condominio, condominiosData, condominiosIds, setStateCondominio } =
		useContext(MovimentacoesContext);
	const [options, setOptions] = useState();
	const [renderReady, setRenderReady] = useState();

	const delayRender = () => {
		setTimeout(() => setRenderReady(true), 500);
	};

	const handleDataChange = () => {
		if (condominiosData && condominiosIds) {
			setOptions(condominiosIds.filter((id) => Boolean(id)).map((id) => condominiosData[id]));
		}
	};

	useEffect(delayRender, []);
	useEffect(handleDataChange, [condominiosData, condominiosIds, condominio]);

	const handleCondominioChange = (_, option) => {
		option && setStateCondominio(option.id);
		option && setCondominio(option);
	};

	return renderReady ? (
		<FilterBox titulo='Selecionar Condomínio' className={className}>
			{condominiosIds && options && condominiosData && (
				<Autocomplete
					options={options || []}
					renderInput={(params) => <TextField {...params} margin='dense' fullWidth />}
					renderOption={(option) => option.nome}
					getOptionLabel={(option) =>
						(option?.nome
							? option.nome
							: condominio && condominiosData
							? condominiosData[condominio]?.nome
							: '') || ''
					}
					onChange={handleCondominioChange}
					value={condominiosData[condominio] || null}
					noOptionsText='Nenhum resultado'
					fullWidth
					disableClearable
				/>
			)}
		</FilterBox>
	) : (
		<div style={{ marginTop: 25 }}>
			<LinearProgress />
		</div>
	);
};

const ContaSelect = memo(({ className }) => {
	const theme = useTheme();
	const { id_conta_bancaria, setContaBancaria, contasData, contasIds } = useContext(MovimentacoesContext);
	const [saldoVisivel, setSaldoVisivel] = useState(false);

	const handleContaChange = (conta) => {
		setContaBancaria(conta);
	};

	return (
		<FilterBox
			paperStyle={{
				padding: '0',
			}}
			margin='30px'
			className={className}
			titulo={
				<Box
					display='flex'
					height='calc(100% - 30px)'
					alignItems='center'
					justifyContent='space-between'
					padding='15px 15px 0 15px'
				>
					<span>Selecionar Conta Bancária</span>
					<TooltipIconButton
						title={saldoVisivel ? 'Ocultar saldo' : 'Mostrar saldo'}
						onClick={() => setSaldoVisivel((v) => !v)}
						disabled={!contasIds || contasIds.length === 0}
					>
						{saldoVisivel ? <VisibilityOffIcon /> : <VisibilityIcon />}
					</TooltipIconButton>
				</Box>
			}
		>
			<Box height='220px' overflow='auto'>
				{contasData &&
					contasIds &&
					contasIds.map((id, index) => (
						<>
							<hr style={{ margin: 0, border: '1px solid #ddd' }} />
							<Box
								title={contasData[id]?.nome_conta}
								id={`selecionar-conta-bancaria-${index}`}
								key={id}
								onClick={() => handleContaChange(contasData[id])}
								display='flex'
								justifyContent='space-between'
								alignItems='center'
								userSelect='none'
								cursor='pointer'
								p='10px'
								m='5px'
								borderRadius='10px'
								css={{
									background: id_conta_bancaria === id ? 'rgba(0,0,0,.225)' : 'transparent',
									userSelect: 'none',
									cursor: 'pointer',
									'&:hover': {
										background: 'rgba(0,0,0,.125)',
									},
								}}
							>
								<Typography noWrap>
									<strong style={{ fontSize: '11pt' }}>{contasData[id]?.nome_conta}</strong>
									<br />
									<span style={{ fontSize: '9pt' }}>
										{tipoConta[contasData[id]?.tipo_conta_bancaria] || 'Caixa'}
									</span>
								</Typography>
								{saldoVisivel ? (
									<Typography
										style={{
											color:
												contasData[id]?.extras.saldo_total > 0
													? theme.blue[500]
													: theme.palette.syndikosRed.main,
										}}
									>
										<ValorDisplayField value={contasData[id]?.extras.saldo_total} />
									</Typography>
								) : (
									<div
										style={{
											color: '#aaa',
											background: '#aaa',
											border: '1px solid #aaa',
											borderRadius: '3px',
											width: '30%',
											height: 5,
										}}
									/>
								)}
							</Box>
						</>
					))}
			</Box>
		</FilterBox>
	);
});

const FilterBoxPeriodoSelect = memo(({ className }) => {
	const { datas_after, setDatas_after, datas_before, setDatas_before } = useContext(MovimentacoesContext);

	return (
		<FilterBox titulo='Selecionar Período' className={className}>
			<PeriodoSelect
				datas_after={datas_after}
				datas_before={datas_before}
				dateRangeInitial={periodoOptions[0]}
				openDatePicker={false}
				setDatas_after={setDatas_after}
				setDatas_before={setDatas_before}
			/>
		</FilterBox>
	);
});

const TotalizadoresSaldo = memo(({ className }) => {
	const { saldoInicial, entradas, saidas, saldoFinal, id_conta_bancaria } = useContext(MovimentacoesContext);
	const style = useStylesTotalizadores();
	const {
		palette: { syndikosRed },
		blue,
	} = useTheme();
	return (
		<Paper className={className}>
			<Box className={style.container}>
				<Box>
					<Box></Box>
					<span>Saldo anterior</span>
					{id_conta_bancaria ? (
						<Typography
							style={{
								color: saldoInicial < 0 ? syndikosRed.main : saldoInicial === 0 ? '' : blue[500],
							}}
						>
							<ValorDisplayField value={saldoInicial} />
						</Typography>
					) : (
						<div
							style={{
								color: '#aaa',
								background: '#aaa',
								border: '1px solid #aaa',
								borderRadius: '3px',
								width: '40%',
								height: 5,
								marginLeft: 'auto',
							}}
						/>
					)}
				</Box>
				<hr style={{ margin: 0, border: '1px solid #ddd' }} />
				<Box color={blue[500]}>
					<TrendingUpIcon />
					<span>Entradas</span>
					{id_conta_bancaria ? (
						<CurrencyFormat value={entradas} />
					) : (
						<div
							style={{
								color: '#aaa',
								background: '#aaa',
								border: '1px solid #aaa',
								borderRadius: '3px',
								width: '40%',
								height: 5,
								marginLeft: 'auto',
							}}
						/>
					)}
				</Box>
				<Box color='#f44336'>
					<TrendingDownIcon />
					<span>Saídas</span>
					{id_conta_bancaria ? (
						<CurrencyFormat value={-Math.abs(saidas)} />
					) : (
						<div
							style={{
								color: '#aaa',
								background: '#aaa',
								border: '1px solid #aaa',
								borderRadius: '3px',
								width: '40%',
								height: 5,
								marginLeft: 'auto',
							}}
						/>
					)}
				</Box>
				<hr style={{ margin: 0, border: '1px solid #ddd' }} />
				<Box>
					<Box></Box>
					<span>Saldo final</span>
					{id_conta_bancaria ? (
						<Typography
							style={{
								color: saldoFinal > 0 ? blue[500] : saldoFinal === 0 ? '' : syndikosRed.main,
							}}
						>
							<CurrencyFormat value={saldoFinal} />
						</Typography>
					) : (
						<div
							style={{
								color: '#aaa',
								background: '#aaa',
								border: '1px solid #aaa',
								borderRadius: '3px',
								width: '40%',
								height: 5,
								marginLeft: 'auto',
							}}
						/>
					)}
				</Box>
			</Box>
		</Paper>
	);
});

const ListComponent = () => {
	const classes = useRemoveButtonStyle();
	const { setModalValue } = useContext(ModalContext);
	const { contasIds, id_conta_bancaria } = useContext(MovimentacoesContext);
	const grid = useStylesGridTemplate();
	return (
		<Box className={grid.container}>
			<CondominioSelect className={grid.condominioSelect} />
			<FilterBoxPeriodoSelect className={grid.periodoSelect} />
			<ContaSelect className={grid.contaSelect} />
			<Paper className={grid.lancamentos}>
				<Box display='flex' flexDirection='column' gridGap='10px' flex='1' alignSelf='center'>
					<Button
						color='primary'
						variant='contained'
						onClick={() => {
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: (
									<FormularioLancamento
										operacao={{
											tipo: 'receita',
											acao: 'cadastrar',
										}}
									/>
								),
							}));
						}}
						disabled={!id_conta_bancaria}
					>
						Lançar Receitas
					</Button>
					<Button
						className={classes.removeButton}
						variant='contained'
						onClick={() => {
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: (
									<FormularioLancamento
										operacao={{
											tipo: 'despesa',
											acao: 'cadastrar',
										}}
									/>
								),
							}));
						}}
						disabled={!id_conta_bancaria}
					>
						Lançar Despesas
					</Button>
					<Button
						color='secondary'
						variant='contained'
						onClick={() => {
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: <FormularioTransferencia />,
							}));
						}}
						disabled={!contasIds || contasIds.length < 2}
					>
						Transferências entre Contas
					</Button>
				</Box>
			</Paper>
			<TotalizadoresSaldo className={grid.totalizadores} />
			<TabelaMovimentacoes className={grid.movimentacoes} />
		</Box>
	);
};

export const MovimentacoesList = (props) => {
	return (
		<ListBase {...props}>
			<Title title='Lançamentos Financeiros' />
			<MovimentacoesContextProvider>
				<ModalContextProvider>
					<ListComponent />
					<CustomDialog />
				</ModalContextProvider>
			</MovimentacoesContextProvider>
		</ListBase>
	);
};

const useStyles = makeStyles((theme) => ({
	warningColor: {
		color: theme.palette.syndikosRed.main,
	},
}));

const TabelaArrecadacoesUnidades = ({ nomeResponsavel, rows: arrecadacoesUnidades }) => {
	const [rows, setRows] = useState(arrecadacoesUnidades);
	const { setModalValue } = useContext(ModalContext);
	const [sort, setSort] = useState({
		field: 'tipo_conta',
		order: 'asc',
	});
	const classes = useStyles();
	const nome_resp = nomeResponsavel ? (
		nomeResponsavel
	) : (
		<span className={classes.warningColor}>Nenhum Responsável Registrado Nessa Data</span>
	);

	const handleSortClick = (field) => {
		const newOrder = sort.order === 'asc' ? 'desc' : 'asc';
		setSort({ field, order: newOrder });
		setRows((r) =>
			r.sort((a, b) => {
				if (field === 'valor') {
					a[field] = Number(a[field]);
					b[field] = Number(b[field]);
					if (a[field] < b[field]) return newOrder === 'asc' ? -1 : 1;
					if (a[field] > b[field]) return newOrder === 'asc' ? 1 : -1;
				} else if (['nome_conta_pai', 'nome_conta', 'tipo_conta'].includes(field)) {
					if (a[field] < b[field]) return newOrder === 'asc' ? -1 : 1;
					if (a[field] > b[field]) return newOrder === 'asc' ? 1 : -1;
				}
				return 0;
			})
		);
	};

	return (
		<CustomDialogBody
			title={
				<>
					<Typography variant='h6'>{'Detalhamento do Recebimento'}</Typography>
					<Typography variant='h6'>
						{`${rows[0]?.tipo_unidade} ${rows[0]?.nome_unidade} - ${rows[0]?.tipo_responsavel}`} {nome_resp}
					</Typography>
				</>
			}
			customActions={
				<ButtonClose onClick={() => setModalValue((v) => ({ ...v, open: false }))}>Fechar</ButtonClose>
			}
			form={{
				component: (
					<TableContainer>
						<Table size='small'>
							<TableHead>
								<TableRow>
									{columnsArrecadacoesUnidades.map((c, i) => (
										<TableCell
											key={c.field}
											sortDirection={sort.field === c.field ? sort.order : false}
											style={i === 0 ? { paddingLeft: 0 } : {}}
										>
											<TableSortLabel
												active={sort.field === c.field}
												direction={sort.field === c.field ? sort.order : 'asc'}
												onClick={() => handleSortClick(c.field)}
											>
												{c.headerName}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row) => {
									return (
										<TableRow hover tabIndex={-1} key={row.id}>
											<TableCell component='th' id={row.id} scope='row' padding='none'>
												{row.nome_conta_pai}
											</TableCell>
											<TableCell>{row.nome_conta}</TableCell>
											<TableCell>{getTipo(row.tipo_conta)}</TableCell>
											<TableCell>
												<NumberFormatBRL
													displayType='text'
													value={row.valor_somado[0] === '-' ? `-${row.valor}` : row.valor}
													fixedDecimalScale
													onChange={() => {}}
													decimalScale={2}
													prefix='R$ '
												/>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				),
			}}
		/>
	);
};

const columnsArrecadacoesUnidades = [
	{ field: 'nome_conta_pai', headerName: 'Conta Título', width: '15vw' },
	{ field: 'nome_conta', headerName: 'Conta', width: '15vw' },
	{ field: 'tipo_conta', headerName: 'Tipo da Conta', width: '10vw' },
	{ field: 'valor', headerName: 'Valor', type: 'number', width: '5vw' },
];

const getTipo = (tipo) => {
	switch (tipo) {
		case 'O':
			return 'Ordinária';
		case 'L':
			return 'Leitura';
		case 'F':
			return 'Fundo';
		case 'E':
			return 'Extraordinária';
		default:
			return '';
	}
};

const ModalContasReceber = ({ ids }) => {
	const { setModalValue } = useContext(ModalContext);

	return (
		<CustomDialogBody
			title={
				<>
					<Typography variant='h6'>{'Detalhamento de Previsões'}</Typography>
					<Typography variant='h6'>{'Contas a Receber'}</Typography>
				</>
			}
			customActions={
				<ButtonClose onClick={() => setModalValue((v) => ({ ...v, open: false }))}>Fechar</ButtonClose>
			}
			form={{
				component: (
					<PrevistosContasReceberList
						showActions={false}
						basePath='/recebimento_manual'
						resource='recebimento_manual'
						sort={{ field: 'nome_unidade', order: 'ASC' }}
						filter={{
							id__in: ids,
						}}
					/>
				),
			}}
		/>
	);
};

const ValorField = ({ record, source, prefix = 'R$ ', suffix }) => (
	<NumberFormatBRL
		prefix={prefix}
		suffix={suffix}
		displayType='text'
		value={record[source] || 0}
		fixedDecimalScale
		onChange={() => {}}
		decimalScale={2}
	/>
);

const TipoResponsavelField = ({ record }) => {
	switch (record?.tipo_responsavel) {
		case 'I':
			return <>Inquilino</>;
		case 'P':
			return <>Proprietário</>;
		default:
			return <> </>;
	}
};

const DateField = ({ source, record }) => {
	let formatada = '';
	try {
		formatada = format(parseISO(record?.[source]), 'dd/MM/yyyy');
	} catch {}
	return <span>{formatada}</span>;
};

const DateTextFieldReferencia = ({ source, record }) => {
	let formatada = '';
	try {
		formatada = format(parseISO(record?.[source]), 'MM/yyyy');
	} catch {}
	return <span>{formatada}</span>;
};

const TextParcelaField = ({ record }) => {
	if (record) {
		if (record.quantidade_parcelas && record.quantidade_parcelas > 1) {
			const numero_parcela = `${record.numero_parcela}`.padStart(3, '0');
			const quantidade_parcelas = `${record.quantidade_parcelas}`.padStart(3, '0');
			return <span>{`${numero_parcela}/${quantidade_parcelas}`}</span>;
		} else {
			return <span>Única</span>;
		}
	} else {
		return <span></span>;
	}
};

const TabelaPrevistosContasReceber = (props) => {
	const { loading } = useListContext();
	return !loading ? (
		<Tabela {...props} disableClickRow>
			<TxtField source='nome_unidade' label='Nome Unidade' />
			<TxtField source='tipo_unidade' label='Tipo Unidade' />
			<TipoResponsavelField source='tipo_responsavel' label='Tipo Responsável' />
			<TxtField source='nome_resp' label='Nome Responsável' />
			<ValorField source='valor_pendente' label='Total' align='right' />
			<DateTextFieldReferencia source='referencia' label='referência' />
			<DateField source='data_vencimento' label='Data de vencimento' />
		</Tabela>
	) : (
		<div style={{ marginTop: 25 }}>
			<LinearProgress />
		</div>
	);
};

const PrevistosContasReceberList = (props) => (
	<List
		{...props}
		empty={false}
		actions={null}
		bulkActionButtons={false}
		title=' '
		perPage={10}
		pagination={
			<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} labelRowsPerPage='Unidades por página' />
		}
	>
		<TabelaPrevistosContasReceber />
	</List>
);

const ModalContasAPagar = ({ ids }) => {
	const { setModalValue } = useContext(ModalContext);

	return (
		<CustomDialogBody
			title={
				<>
					<Typography variant='h6'>{'Detalhamento de Previsões'}</Typography>
					<Typography variant='h6'>{'Contas a Pagar'}</Typography>
				</>
			}
			customActions={
				<ButtonClose onClick={() => setModalValue((v) => ({ ...v, open: false }))}>Fechar</ButtonClose>
			}
			form={{
				component: (
					<PrevistosContasAPagarList
						showActions={false}
						basePath='/parcela'
						resource='parcela'
						sort={{ field: 'fornecedor_nome', order: 'ASC' }}
						filter={{
							id__in: ids,
						}}
					/>
				),
			}}
		/>
	);
};

const TabelaPrevistosContasAPagar = (props) => {
	const { loading } = useListContext();
	return !loading ? (
		<Tabela {...props} disableClickRow>
			<TxtField source='fornecedor_nome' label='Fornecedor' />
			<ValorField source='valor_pendente' label='Valor' align='right' />
			<DateField source='data_vencimento' label='Data Previsão de Pagamento' />
			<TextParcelaField source='parcela' label='Parcelas' />
		</Tabela>
	) : (
		<div style={{ marginTop: 25 }}>
			<LinearProgress />
		</div>
	);
};

const PrevistosContasAPagarList = (props) => (
	<List
		{...props}
		empty={false}
		actions={null}
		bulkActionButtons={false}
		title=' '
		perPage={10}
		pagination={
			<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]} labelRowsPerPage='Unidades por página' />
		}
	>
		<TabelaPrevistosContasAPagar />
	</List>
);

const HeaderCellSortable = ({ c, i, handleSortClick, sort }) => (
	<TableCell
		key={c.field}
		sortDirection={sort.field === c.field ? sort.order : false}
		style={i === 0 ? { paddingLeft: 0 } : {}}
	>
		<TableSortLabel
			active={sort.field === c.field}
			direction={sort.field === c.field ? sort.order : 'asc'}
			onClick={() => handleSortClick(c.field)}
		>
			{c.headerName}
		</TableSortLabel>
	</TableCell>
);

const VisualizarAnexosIcons = ({ anexo, title }) => (
	<TooltipIconButton
		color='primary'
		title={title}
		disabled={!anexo}
		onClick={() => {
			if (anexo) window.open(anexo, '_blank');
		}}
	>
		{anexo ? <VisibilityIcon /> : <VisibilityOffIcon />}
	</TooltipIconButton>
);

const TabelaLancDespBaixaManual = ({ fornecedor, rows: baixaLancamentosDespesa, parcela }) => {
	const [rows, setRows] = useState(baixaLancamentosDespesa);
	const { setModalValue } = useContext(ModalContext);
	const [sort, setSort] = useState({
		field: 'tipo_conta_despesa',
		order: 'asc',
	});

	const handleSortClick = (field) => {
		const newOrder = sort.order === 'asc' ? 'desc' : 'asc';
		setSort({ field, order: newOrder });
		setRows((r) =>
			r.sort((a, b) => {
				if (field === 'valor') {
					a[field] = Number(a[field]);
					b[field] = Number(b[field]);
					if (a[field] < b[field]) return newOrder === 'asc' ? -1 : 1;
					if (a[field] > b[field]) return newOrder === 'asc' ? 1 : -1;
				} else if (
					['nome_conta_pai', 'nome_conta_despesa', 'tipo_conta_despesa', 'tipo_lancamento'].includes(field)
				) {
					if (a[field] < b[field]) return newOrder === 'asc' ? -1 : 1;
					if (a[field] > b[field]) return newOrder === 'asc' ? 1 : -1;
				}
				return 0;
			})
		);
	};

	return (
		<CustomDialogBody
			title={
				<div>
					<Typography variant='h6'>{'Detalhamento do Pagamento'}</Typography>
					<Typography variant='h6'>{`Fornecedor ${fornecedor}`}</Typography>
					<Typography variant='h6'>
						{'Anexo: '}
						<VisualizarAnexosIcons
							anexo={parcela?.anexo_ordem_compra_servico}
							title='Visualizar Ordem de Compra/Serviço'
						/>
						<VisualizarAnexosIcons anexo={parcela?.anexo_nf} title='Visualizar Nota Fiscal' />
						<VisualizarAnexosIcons anexo={parcela?.anexo_boleto} title='Visualizar Boleto' />
						<VisualizarAnexosIcons
							anexo={parcela?.anexo_comprovante_pagamento}
							title='Visualizar Comprovante Pagamento'
						/>
					</Typography>
				</div>
			}
			customActions={
				<ButtonClose onClick={() => setModalValue((v) => ({ ...v, open: false }))}>Fechar</ButtonClose>
			}
			form={{
				component: (
					<TableContainer>
						<Table size='small'>
							<TableHead>
								<TableRow>
									{columnsLancDespBaixaManual.map((c, i) => (
										<HeaderCellSortable {...{ c, i, handleSortClick, sort }} />
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row) => {
									return (
										<TableRow hover tabIndex={-1} key={row.id}>
											<TableCell component='th' id={row.id} scope='row' padding='none'>
												{row.nome_conta_pai}
											</TableCell>
											<TableCell>{row.nome_conta_despesa}</TableCell>
											<TableCell>{getTipo(row.tipo_conta_despesa)}</TableCell>
											<TableCell>{getTipoLancamento(row.tipo_lancamento)}</TableCell>
											<TableCell>
												<NumberFormatBRL
													displayType='text'
													value={row.valor}
													fixedDecimalScale
													onChange={() => {}}
													decimalScale={2}
													prefix='R$'
												/>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				),
			}}
		/>
	);
};

const columnsLancDespBaixaManual = [
	{ field: 'nome_conta_pai', headerName: 'Conta Título', width: '15vw' },
	{
		field: 'nome_conta_despesa',
		headerName: 'Conta de Despesa',
		width: '15vw',
	},
	{ field: 'tipo_conta_despesa', headerName: 'Tipo da Conta', width: '10vw' },
	{ field: 'tipo_lancamento', headerName: 'Tipo Lançamento', width: '10vw' },
	{ field: 'valor', headerName: 'Valor', type: 'number', width: '5vw' },
];

const getTipoLancamento = (tipo) => {
	switch (tipo) {
		case 'C':
			return 'Condominio';
		case 'G':
			return 'Grupo';
		default:
			return '';
	}
};
