import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useDataProvider, useGetList, useGetOne, useNotify, useRefresh } from 'react-admin';

import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import WarningIcon from '@material-ui/icons/Warning';

import { CustomDialogBody, ModalContext } from '../../../../common/ModalContext';
import { ArrecadacoesContext } from '../../ArrecadacoesContext';
import { CondominiosContext } from '../../../../../context/CondominioContextProvider';
import { ButtonCancel } from '../../../../common/buttons/ButtonCancel';
import { BotaoVoltar } from '../../../../common/buttons/BotaoVoltar';
import { BotaoProximo } from '../../../../common/buttons/BotaoProximo';
import { ButtonConfirm } from '../../../../common/buttons/ButtonConfirm';
import { BoxButtonModalListaUnidade, BoxListaUnidade } from './components';
import {
	BoxAddConta,
	BoxAddLeitura,
	BoxArrecPara,
	BoxPeriodicidade,
	BoxParcelas,
	BoxDivisao,
	BoxUnidade,
	BoxTipos,
	BoxGrupos,
	BoxTipoFundo,
	BoxTipoLancamento,
	BoxSelectLancDesp,
	BoxAddContasCalc,
	BoxValorDesc,
	BoxSelectLancDespNaoPaga,
} from './modalComponents/AdicionarArrecadacaoComponents';
import { BoxArrecadacaoParaTipoDivisao } from './modalComponents/EditarArrecadacaoComponents';
import BoxIntervaloConta from './modalComponents/BoxIntervaloConta';
import BoxIntervalo from './modalComponents/BoxIntervalo';

export const AdicionarArrecadacao = ({ tipo }) => {
	const { setModalValue } = useContext(ModalContext);
	const { referencia, id_identificacao_arrecadacao, carregaValoresCB } = useContext(ArrecadacoesContext);
	const { condominioSelecionado } = useContext(CondominiosContext);
	const [valor, setValor] = useState();
	const [desc, setDesc] = useState();
	const [contaReceita, setContaReceita] = useState();
	const [arrecPara, setArrecPara] = useState();
	const [currentStep, setCurrentStep] = useState(0);
	const [divisao, setDivisao] = useState();
	const [unidades, setUnidades] = useState([]);
	const [grupos, setGrupos] = useState([]);
	const [tipos, setTipos] = useState([]);
	const [configUnidadesArrecadacao, setConfigUnidadesArrecadacao] = useState([]);
	const [tipoLancamento, setTipoLancamento] = useState();
	const [tipoFundo, setTipoFundo] = useState();
	const dataProvider = useDataProvider();
	const [contaBDesp, setContaBDesp] = useState();
	const [datasAfter, setDatasAfter] = useState();
	const [datasBefore, setDatasBefore] = useState();
	const [contasDespCalc, setContasDespCalc] = useState([]);
	const [contasRecCalc, setContasRecCalc] = useState([]);
	const [lancDesp, setLancDesp] = useState([]);
	const [agrupar, setAgrupar] = useState(false);
	const [periodicidade, setPeriodicidade] = useState('1');
	const [saving, setSaving] = useState(false);
	const [parcelas, setParcelas] = useState(2);
	const [imprimirApenasNomeConta, setImprimirApenasNomeConta] = useState(false);

	const notify = useNotify();
	const refresh = useRefresh();

	const resetFields = () => {
		setGrupos([]);
		setTipos([]);
		setUnidades([]);
		setConfigUnidadesArrecadacao([]);
		setDivisao(null);
	};

	useEffect(() => setDesc(contaReceita?.nome), [contaReceita]);

	const stepComponents = () => {
		switch (tipo) {
			case 'receita':
				return {
					0: {
						nextStep: 'P',
						component: <BoxAddConta {...{ contaReceita, setContaReceita, tipo }} />,
						valid: !!contaReceita,
					},
					P: {
						prevStep: 0,
						component: (
							<BoxPeriodicidade
								{...{
									nextStep: 1,
									setCurrentStep,
									setPeriodicidade,
									setParcelas,
								}}
							/>
						),
					},
					D: {
						prevStep: 'P',
						nextStep: 1,
						component: <BoxParcelas parcelas={parcelas} setParcelas={setParcelas} />,
						valid: !!parcelas && parseInt(parcelas) > 1,
					},
					1: {
						prevStep: periodicidade === '2' ? 'D' : 'P',
						component: (
							<BoxArrecPara
								{...{
									setArrecPara,
									setCurrentStep,
									resetFields,
								}}
							/>
						),
					},
					ListaUnidade: {
						prevStep: 1,
						nextStep: 6,
						component: (
							<BoxListaUnidade
								{...{
									configUnidadesArrecadacao,
									setConfigUnidadesArrecadacao,
								}}
							/>
						),
						valid: !!configUnidadesArrecadacao?.length,
					},
					2: {
						prevStep:
							(tipos?.length > 0 ? 4 : false) ||
							(grupos?.length > 0 ? 5 : false) ||
							(unidades?.length > 0 ? 3 : false) ||
							1,
						nextStep: 6,
						component: <BoxDivisao {...{ setDivisao, divisao, unidades }} />,
						valid: !!divisao,
					},
					3: {
						prevStep: 1,
						nextStep: 2,
						component: <BoxUnidade {...{ setUnidades, unidades }} />,
						valid: unidades.length > 0,
					},
					4: {
						prevStep: 1,
						nextStep: 2,
						component: <BoxTipos {...{ tipos, setTipos }} />,
						valid: tipos?.length > 0,
					},
					5: {
						prevStep: 1,
						nextStep: 2,
						component: <BoxGrupos {...{ grupos, setGrupos }} />,
						valid: grupos?.length > 0,
					},
					6: {
						prevStep: arrecPara === 5 ? 'ListaUnidade' : 2,
						component: (
							<BoxValorDesc
								{...{
									valor,
									setValor,
									desc,
									setDesc,
									arrecPara,
								}}
							/>
						),
						end: true,
						valid: arrecPara === 5 ? desc : desc && valor,
					},
				};
			case 'leitura':
				return {
					0: {
						component: (
							<BoxPeriodicidade
								{...{
									nextStep: 1,
									setCurrentStep,
									setPeriodicidade,
									setParcelas,
								}}
							/>
						),
					},
					D: {
						prevStep: 0,
						nextStep: 1,
						component: <BoxParcelas parcelas={parcelas} setParcelas={setParcelas} />,
						valid: !!parcelas && parseInt(parcelas) > 1,
					},
					1: {
						prevStep: periodicidade === '2' ? 'D' : 0,
						end: true,
						component: <BoxAddLeitura {...{ contaReceita, setContaReceita }} />,
						valid: !!contaReceita && !saving,
					},
				};
			case 'fundo':
				return {
					0: {
						nextStep: 'P',
						component: <BoxAddConta {...{ contaReceita, setContaReceita, tipo }} />,
						valid: !!contaReceita,
					},
					P: {
						prevStep: 0,
						component: (
							<BoxPeriodicidade
								{...{
									nextStep: 1,
									setCurrentStep,
									setPeriodicidade,
									setParcelas,
								}}
							/>
						),
					},
					D: {
						prevStep: 'P',
						nextStep: 1,
						component: <BoxParcelas parcelas={parcelas} setParcelas={setParcelas} />,
						valid: !!parcelas && parseInt(parcelas) > 1,
					},
					1: {
						prevStep: periodicidade === '2' ? 'D' : 'P',
						nextStep: tipoLancamento === 'VLF' ? 2 : 9,
						component: <BoxTipoLancamento {...{ tipoLancamento, setTipoLancamento }} />,
						valid: !!tipoLancamento,
					},
					2: {
						prevStep: 1,
						nextStep: 3,
						component: <BoxTipoFundo {...{ tipoFundo, setTipoFundo }} />,
						valid: !!tipoFundo,
					},
					3: {
						prevStep: 2,
						component: (
							<BoxArrecPara
								{...{
									setArrecPara,
									setCurrentStep,
									resetFields,
								}}
							/>
						),
					},
					ListaUnidade: {
						prevStep: 3,
						nextStep: 8,
						component: (
							<BoxListaUnidade
								{...{
									configUnidadesArrecadacao,
									setConfigUnidadesArrecadacao,
								}}
							/>
						),
						valid: !!configUnidadesArrecadacao?.length,
					},
					4: {
						prevStep:
							(unidades?.length > 0 ? 5 : false) ||
							(tipos?.length > 0 ? 6 : false) ||
							(grupos?.length > 0 ? 7 : false) ||
							3,
						nextStep: 8,
						component: <BoxDivisao {...{ setDivisao, divisao, unidades }} />,
						valid: !!divisao,
					},
					5: {
						prevStep: 3,
						nextStep: 4,
						component: <BoxUnidade {...{ setUnidades, unidades }} />,
						valid: unidades.length > 0,
					},
					6: {
						prevStep: 3,
						nextStep: 4,
						component: <BoxTipos {...{ tipos, setTipos }} />,
						valid: tipos?.length > 0,
					},
					7: {
						prevStep: 3,
						nextStep: 4,
						component: <BoxGrupos {...{ grupos, setGrupos }} />,
						valid: grupos?.length > 0,
					},
					8: {
						prevStep: arrecPara === 5 ? 'ListaUnidade' : 3,
						component: (
							<BoxValorDesc
								{...{
									desc,
									setDesc,
									valor,
									setValor,
									arrecPara,
								}}
							/>
						),
						end: true,
						valid: arrecPara === 5 ? desc : desc && valor,
					},
					9: {
						prevStep: 1,
						component: (
							<BoxArrecPara
								{...{
									hideListaUnidade: true,
									setArrecPara,
									setCurrentStep,
									resetFields,
									titulo: 'Fundo de Reserva por',
								}}
							/>
						),
					},
					10: {
						nextStep: 14,
						prevStep:
							(unidades?.length > 0 ? 11 : false) ||
							(tipos?.length > 0 ? 12 : false) ||
							(grupos?.length > 0 ? 13 : false) ||
							9,
						component: (
							<BoxAddContasCalc
								{...{
									contaReceita,
									contasDespCalc,
									contasRecCalc,
									setContasDespCalc,
									setContasRecCalc,
								}}
							/>
						),
						valid: contasDespCalc?.length > 0 || contasRecCalc?.length > 0,
					},
					11: {
						prevStep: 9,
						nextStep: 10,
						component: <BoxUnidade {...{ setUnidades, unidades }} />,
						valid: unidades.length > 0,
					},
					12: {
						prevStep: 9,
						nextStep: 10,
						component: <BoxTipos {...{ tipos, setTipos }} />,
						valid: tipos?.length > 0,
					},
					13: {
						prevStep: 9,
						nextStep: 10,
						component: <BoxGrupos {...{ grupos, setGrupos }} />,
						valid: grupos?.length > 0,
					},
					14: {
						prevStep: 10,
						end: true,
						component: (
							<BoxValorDesc
								percentual
								{...{
									desc,
									setDesc,
									valor,
									setValor,
								}}
							/>
						),
						valid: desc && valor,
					},
				};
			case 'despesa':
				return {
					0: {
						nextStep: 1,
						component: (
							<BoxIntervaloConta
								{...{ setDatasAfter, setDatasBefore }}
								conta={contaBDesp}
								setConta={setContaBDesp}
							/>
						),
						valid: datasAfter && datasBefore && contaBDesp,
					},
					1: {
						prevStep: 0,
						nextStep: 2,
						component: (
							<BoxSelectLancDesp
								{...{
									contaBDesp,
									datasAfter,
									datasBefore,
									setLancDesp,
									lancDesp,
									agrupar,
									setAgrupar,
									imprimirApenasNomeConta,
									setImprimirApenasNomeConta,
								}}
							/>
						),
						valid: lancDesp?.length > 0,
					},
					2: {
						prevStep: 1,
						component: (
							<BoxArrecPara
								{...{
									setArrecPara,
									setCurrentStep,
									resetFields,
									hideUnidade: false,
									hideListaUnidade: true,
								}}
							/>
						),
					},
					3: {
						prevStep:
							(tipos.length > 0 ? 5 : false) ||
							(grupos.length > 0 ? 6 : false) ||
							(unidades.length > 0 ? 4 : false) ||
							2,
						end: true,
						component: <BoxDivisao {...{ setDivisao, divisao, unidades }} />,
						valid: !!divisao,
					},
					4: {
						prevStep: 2,
						nextStep: 3,
						component: <BoxUnidade {...{ setUnidades, unidades }} />,
						valid: unidades?.length > 0,
					},
					5: {
						prevStep: 2,
						nextStep: 3,
						component: <BoxTipos {...{ tipos, setTipos }} />,
						valid: tipos?.length > 0,
					},
					6: {
						prevStep: 2,
						nextStep: 3,
						component: <BoxGrupos {...{ grupos, setGrupos }} />,
						valid: grupos?.length > 0,
					},
				};
			case 'desconto':
				return {
					0: {
						nextStep: 1,
						component: <BoxAddConta {...{ contaReceita, setContaReceita, tipo }} />,
						valid: !!contaReceita,
					},
					P: {
						prevStep: 0,
						component: (
							<BoxPeriodicidade
								{...{
									nextStep: 1,
									setCurrentStep,
									setPeriodicidade,
									setParcelas,
								}}
							/>
						),
					},
					D: {
						prevStep: 'P',
						component: <BoxParcelas parcelas={parcelas} setParcelas={setParcelas} />,
					},
					1: {
						prevStep: periodicidade === '2' ? 'D' : 'P',
						component: (
							<BoxArrecPara
								{...{
									setArrecPara,
									setCurrentStep,
									resetFields,
								}}
							/>
						),
					},
					ListaUnidade: {
						prevStep: 1,
						nextStep: 6,
						component: (
							<BoxListaUnidade
								{...{
									configUnidadesArrecadacao,
									setConfigUnidadesArrecadacao,
								}}
							/>
						),
						valid: !!configUnidadesArrecadacao.length,
					},
					2: {
						prevStep:
							(unidades?.length > 0 ? 3 : false) ||
							(tipos?.length > 0 ? 4 : false) ||
							(grupos?.length > 0 ? 5 : false) ||
							1,
						nextStep: 6,
						component: <BoxDivisao {...{ setDivisao, divisao, unidades }} />,
						valid: !!divisao,
					},
					3: {
						prevStep: 1,
						nextStep: 2,
						component: <BoxUnidade {...{ setUnidades, unidades }} />,
						valid: unidades.length > 0,
					},
					4: {
						prevStep: 1,
						nextStep: 2,
						component: <BoxTipos {...{ tipos, setTipos }} />,
						valid: tipos?.length > 0,
					},
					5: {
						prevStep: 1,
						nextStep: 2,
						component: <BoxGrupos {...{ grupos, setGrupos }} />,
						valid: grupos?.length > 0,
					},
					6: {
						prevStep: arrecPara === 5 ? 'ListaUnidade' : 2,
						component: (
							<BoxValorDesc
								{...{
									desc,
									setDesc,
									valor,
									setValor,
									arrecPara,
								}}
							/>
						),
						end: true,
						valid: arrecPara === 5 ? desc : desc && valor,
					},
				};
			case 'contas a pagar':
				return {
					0: {
						nextStep: 1,
						component: <BoxIntervalo {...{ setDatasAfter, setDatasBefore }} />,
						valid: datasAfter && datasBefore,
					},
					1: {
						prevStep: 0,
						nextStep: 2,
						component: (
							<BoxSelectLancDespNaoPaga
								{...{
									datasAfter,
									datasBefore,
									setLancDesp,
									lancDesp,
									agrupar,
									setAgrupar,
									imprimirApenasNomeConta,
									setImprimirApenasNomeConta,
								}}
							/>
						),
						valid: lancDesp?.length > 0,
					},
					2: {
						prevStep: 1,
						component: (
							<BoxArrecPara
								{...{
									setArrecPara,
									setCurrentStep,
									resetFields,
									hideUnidade: false,
									hideListaUnidade: true,
								}}
							/>
						),
					},
					3: {
						prevStep:
							(tipos.length > 0 ? 5 : false) ||
							(grupos.length > 0 ? 6 : false) ||
							(unidades.length > 0 ? 4 : false) ||
							2,
						end: true,
						component: <BoxDivisao {...{ setDivisao, divisao, unidades }} />,
						valid: !!divisao,
					},
					4: {
						prevStep: 2,
						nextStep: 3,
						component: <BoxUnidade {...{ unidades, setUnidades }} />,
						valid: unidades?.length > 0,
					},
					5: {
						prevStep: 2,
						nextStep: 3,
						component: <BoxTipos {...{ tipos, setTipos }} />,
						valid: tipos?.length > 0,
					},
					6: {
						prevStep: 2,
						nextStep: 3,
						component: <BoxGrupos {...{ grupos, setGrupos }} />,
						valid: grupos?.length > 0,
					},
				};
			default:
				return {};
		}
	};

	const getSubmitData = () => {
		let data = {
			valor,
			complemento: desc,
			referencia,
			id_identificacao_arrecadacao,
			id_condominio: condominioSelecionado?.id,
			tipo_de_arrecadacao: tipo.toUpperCase(),
			arrecadacao_para: arrecPara,
			tipo_divisao: divisao,
			periodicidade,
			parcelas,
			imprimir_apenas_nome_conta: imprimirApenasNomeConta,
		};
		if (arrecPara === 5 || divisao === 3) {
			data.arrecadacao_para = 5;
			data.tipo_divisao = 3;
			data.config_unidades_arrecadacao = configUnidadesArrecadacao;
			data.valor = 0;
		}
		if (contaReceita) {
			data.conta_receita = contaReceita.id;
		}
		if (unidades) {
			data.ids_unidades = unidades.map((g) => g.id);
		}
		if (grupos?.length > 0) {
			data.grupos_unidade = grupos.map((g) => g.id);
		}
		if (tipos?.length > 0) {
			data.tipos_unidades = tipos.map((g) => g.id);
		}
		if (tipoLancamento) {
			data.tipo_lancamento = tipoLancamento;
		}
		if (tipoFundo) {
			data.tipo_conta_para_fundo = tipoFundo;
		}
		if (tipo === 'despesa' || tipo === 'contas a pagar') {
			data = {
				arrecadacoes_por_despesa: agrupar
					? Object.values(
							lancDesp.reduce((arrecadacoesPorConta, l) => {
								if (l?.conta_despesa_id || l?.conta_despesa) {
									if (!arrecadacoesPorConta[l?.conta_despesa_id || l?.conta_despesa]) {
										arrecadacoesPorConta[l?.conta_despesa_id || l?.conta_despesa] = {
											...data,
											conta_despesa: l?.conta_despesa_id || l?.conta_despesa,
											parcelas_despesa: [],
											detalhamentos_lancamentos_despesa: [],
											lancamentos_baixa_despesa: [],
										};
									}

									arrecadacoesPorConta[l?.conta_despesa_id || l?.conta_despesa][
										{
											[Reflect.has(l, 'conta_baixa')]: 'lancamentos_baixa_despesa',
											[Reflect.has(l, 'conta_despesa_id')]: 'parcelas_despesa',
											[Reflect.has(l, 'fornecedor_id')]: 'detalhamentos_lancamentos_despesa',
										}[true]
									].push(l.id);
								}

								return arrecadacoesPorConta;
							}, {})
					  )
					: lancDesp.map((l) => {
							const campoValorDaOrigemDoLancamentoDespesa = {
								[true]: () => {},
								[l.origem === 'BC']: () => ({ lancamentos_baixa_despesa: [l.id] }),
								[Reflect.has(l, 'conta_despesa_id')]: () => ({ parcelas_despesa: [l.id] }),
								[Reflect.has(l, 'fornecedor_id')]: () => ({
									detalhamentos_lancamentos_despesa: [l.id],
								}),
							}[true]();
							return { ...data, ...campoValorDaOrigemDoLancamentoDespesa };
					  }),
			};
		}
		if (tipoLancamento === 'PRC') {
			data.contas_despesas_para_calculo = contasDespCalc.filter((o) => o.classe_conta === 'A').map((c) => c.id);
			data.contas_receitas_para_calculo = contasRecCalc.filter((o) => o.classe_conta === 'A').map((c) => c.id);
		}

		return data;
	};
	const handleSubmit = () => {
		setSaving(true);
		dataProvider
			.safeCreate('arrecadacao', {
				data: getSubmitData(),
			})
			.then(() => {
				notify('Arrecadação cadastrada com sucesso');
				setModalValue((v) => ({ ...v, open: false }));
				refresh();
				try {
					carregaValoresCB()();
				} catch (e) {}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				const erroMsg =
					(tipo === 'despesa'
						? Object.values((e?.response?.data || [])[0] || {})[0]
						: Object.values(e?.response?.data || {})[0]) || 'Erro inesperado';
				notify(erroMsg, 'warning', {}, false, 10000);
				setSaving(false);
			});
	};

	const acoes = (
		<>
			<ButtonCancel
				id='botao-cancelar-add-arrecadacao'
				onClick={() => setModalValue((v) => ({ ...v, open: false }))}
			/>
			<BotaoVoltar
				id='botao-voltar-add-arrecadacao'
				disabled={currentStep === 0}
				onClick={() => setCurrentStep(stepComponents()[currentStep].prevStep)}
			/>
			{stepComponents()[currentStep].end ? (
				<ButtonConfirm
					id='botao-submit-add-arrecadacao'
					onClick={handleSubmit}
					disabled={!stepComponents()[currentStep].valid || saving}
				/>
			) : (
				<BotaoProximo
					id='botao-proximo-add-arrecadacao'
					disabled={!stepComponents()[currentStep].valid}
					onClick={() => setCurrentStep(stepComponents()[currentStep].nextStep)}
				/>
			)}
		</>
	);

	return (
		<CustomDialogBody
			title={currentStep === 'ListaUnidade' ? 'Adicionar valores' : `Adicionar ${tipo}`}
			customActions={acoes}
			form={{
				handleSubmit: () => {},
				component: (
					<Box
						css={{ transition: 'all 200ms ease' }}
						minHeight='60px'
						display='grid'
						alignItems='center'
						width='100%'
					>
						{stepComponents()[currentStep].component}
					</Box>
				),
			}}
		/>
	);
};

export const EditarArrecadacao = ({ record }) => {
	const dataProvider = useDataProvider();
	const { carregaValoresCB } = useContext(ArrecadacoesContext);
	const { condominioSelecionado } = useContext(CondominiosContext);
	const {
		data: {
			contas_receitas_para_calculo,
			contas_despesas_para_calculo,
			arrecadacao_para,
			tipo_divisao,
			unidades,
			tipos_unidades,
			grupos_unidade,
			tipo_de_arrecadacao,
			config_unidades_arrecadacao,
			...data
		},
	} = useGetOne('arrecadacao', record.id);
	const [valor, setValor] = useState(record.valor);
	const [desc, setDesc] = useState(record.complemento);
	const notify = useNotify();
	const refresh = useRefresh();
	const [valid, setValid] = useState(false);
	const [contasDespCalc, setContasDespCalc] = useState([]);
	const [contasRecCalc, setContasRecCalc] = useState([]);
	const [arrecadacaoPara, setArrecadacaoPara] = useState();
	const [tipoDivisao, setTipoDivisao] = useState();
	const [idsUnidades, setIdsUnidades] = useState([]);
	const [tiposUnidades, setTiposUnidades] = useState([]);
	const [gruposUnidade, setGruposUnidade] = useState([]);
	const [configUnidadesArrecadacao, setConfigUnidadesArrecadacao] = useState([]);
	const [tipoArrecadacao, setTipoArrecadacao] = useState();

	const { data: contaRecData } = useGetOne('contas_receita', data.conta_receita);

	const { data: contasRec, ids: contasRecIds } = useGetList(
		'contas_receita',
		{ page: 1, perPage: 10000 },
		{ field: 'nome', order: 'ASC' },
		{
			id_plano_condominio__condominios: condominioSelecionado?.id,
			classe_conta: 'A',
		}
	);
	const { data: contasDesp, ids: contasDespIds } = useGetList(
		'contas_despesa',
		{ page: 1, perPage: 10000 },
		{ field: 'nome', order: 'ASC' },
		{
			id_plano_condominio__condominios: condominioSelecionado?.id,
			classe_conta: 'A',
		}
	);

	const [contaReceita, setContaReceita] = useState(contaRecData);

	useEffect(() => {
		if (contaRecData?.id) {
			setContaReceita(contaRecData);
		}
	}, [contaRecData]);

	useEffect(() => {
		if (contasRec && contas_receitas_para_calculo) {
			setContasRecCalc(
				contas_receitas_para_calculo
					.filter((id) => !!contasRec[id])
					.map((id) => ({ ...contasRec[id], tipoConta: 'Receita' }))
			);
		}
	}, [contasRec, contas_receitas_para_calculo]);
	useEffect(() => {
		if (contasDesp && contas_despesas_para_calculo) {
			setContasDespCalc(
				contas_despesas_para_calculo
					.filter((id) => !!contasDesp[id])
					.map((id) => ({ ...contasDesp[id], tipoConta: 'Despesa' }))
			);
		}
	}, [contasDesp, contas_despesas_para_calculo]);

	const updateStates = useCallback(() => {
		if (![undefined, null].includes(arrecadacao_para)) setArrecadacaoPara(`${arrecadacao_para}`);
		if (![undefined, null].includes(tipo_divisao)) setTipoDivisao(`${tipo_divisao}`);
		if (unidades) setIdsUnidades(arrecadacao_para === 2 ? unidades || [] : []);
		if (tipos_unidades) setTiposUnidades(tipos_unidades || []);
		if (grupos_unidade) setGruposUnidade(grupos_unidade || []);
		if (config_unidades_arrecadacao) setConfigUnidadesArrecadacao(config_unidades_arrecadacao || []);
		if (tipo_de_arrecadacao) setTipoArrecadacao(tipo_de_arrecadacao);
	}, [
		arrecadacao_para,
		tipo_divisao,
		unidades,
		tipos_unidades,
		grupos_unidade,
		config_unidades_arrecadacao,
		tipo_de_arrecadacao,
	]);
	useEffect(updateStates, [
		arrecadacao_para,
		tipo_divisao,
		unidades,
		tipos_unidades,
		grupos_unidade,
		tipo_de_arrecadacao,
	]);

	const tipo = () => {
		switch (record.tipo_conta) {
			case 'L':
				return 'leitura';
			case 'F':
				return 'fundo';
			default:
				return 'receita';
		}
	};
	const validate = useCallback(() => {
		const originalConfigUnidadesArrecadacaoDados = (config_unidades_arrecadacao || []).reduce((configs, config) => {
			configs[config.unidade] = config;
			return configs;
		}, {});
		setValid(
			desc &&
				valor &&
				(record.tipo_lancamento === 'PRC'
					? contasRecIds.map((id) => contasRec[id]) !== contasRecCalc ||
					  contasDespIds.map((id) => contasDesp[id]) !== contasDespCalc
					: true) &&
				(data?.conta_despesa ? true : contaReceita) &&
				(desc !== record.complemento ||
					valor !== record.valor ||
					(!data?.conta_despesa && contaReceita?.id !== data?.conta_receita) ||
					(record.tipo_lancamento === 'PRC' && contasDespCalc.length > 0) ||
					contasRecCalc.length > 0 ||
					arrecadacaoPara !== `${arrecadacao_para}` ||
					tipoDivisao !== `${tipo_divisao}` ||
					!!(
						unidades &&
						arrecadacaoPara === '2' &&
						(idsUnidades.length !== unidades.length ||
							idsUnidades.filter((id) => !unidades.includes(id)).length)
					) ||
					!!(
						tipos_unidades &&
						arrecadacaoPara === '4' &&
						(tiposUnidades.length !== tipos_unidades.length ||
							tiposUnidades.filter((id) => !tipos_unidades.includes(id)).length)
					) ||
					!!(
						grupos_unidade &&
						arrecadacaoPara === '1' &&
						(gruposUnidade.length !== grupos_unidade.length ||
							gruposUnidade.filter((id) => !grupos_unidade.includes(id)).length)
					) ||
					!!(
						config_unidades_arrecadacao &&
						arrecadacaoPara === '5' &&
						configUnidadesArrecadacao?.length &&
						(configUnidadesArrecadacao.length !== config_unidades_arrecadacao.length ||
							configUnidadesArrecadacao.find(
								(config) =>
									!originalConfigUnidadesArrecadacaoDados[config.unidade] ||
									parseFloat(originalConfigUnidadesArrecadacaoDados[config.unidade].valor) !==
										parseFloat(config.valor)
							))
					)) &&
				(['RECEITA', 'DESPESA', 'FUNDO', 'DESCONTO'].includes(tipoArrecadacao)
					? (record.tipo_lancamento === 'PRC' ? true : ['0', '1', '2', '3'].includes(tipoDivisao)) &&
					  (tipoArrecadacao === 'DESPESA'
							? tipoDivisao !== '3' && ['0', '1', '2', '4'].includes(arrecadacaoPara)
							: ['0', '1', '2', '4', '5'].includes(arrecadacaoPara)) &&
					  (arrecadacaoPara === '0' ||
							(arrecadacaoPara === '1' && gruposUnidade.length > 0) ||
							(arrecadacaoPara === '2' && idsUnidades.length > 0) ||
							(arrecadacaoPara === '4' && tiposUnidades.length > 0) ||
							(arrecadacaoPara === '5' && (configUnidadesArrecadacao?.length || 0) > 0))
					: true)
		);
	}, [
		desc,
		valor,
		contaReceita,
		data,
		record,
		contasDesp,
		contasDespCalc,
		contasDespIds,
		contasRec,
		contasRecCalc,
		contasRecIds,
		tipoDivisao,
		arrecadacaoPara,
		idsUnidades,
		gruposUnidade,
		tiposUnidades,
		configUnidadesArrecadacao,
		tipoArrecadacao,
		arrecadacao_para,
		tipo_divisao,
		unidades,
		tipos_unidades,
		grupos_unidade,
		config_unidades_arrecadacao,
	]);
	useEffect(validate, [
		desc,
		valor,
		contaReceita,
		data,
		record,
		contasDesp,
		contasDespCalc,
		contasDespIds,
		contasRec,
		contasRecCalc,
		contasRecIds,
		tipoDivisao,
		arrecadacaoPara,
		idsUnidades?.length,
		gruposUnidade?.length,
		tiposUnidades?.length,
		configUnidadesArrecadacao?.length,
		tipoArrecadacao,
		arrecadacao_para,
		tipo_divisao,
	]);

	const handleSubmit = () => {
		dataProvider
			.update('arrecadacao', {
				id: record.id,
				data: {
					valor,
					complemento: desc,
					conta_receita: contaReceita?.id,
					contas_despesas_para_calculo: contasDespCalc.filter((o) => o.classe_conta === 'A').map((c) => c.id),
					contas_receitas_para_calculo: contasRecCalc.filter((o) => o.classe_conta === 'A').map((c) => c.id),
					tipo_divisao: tipoDivisao,
					arrecadacao_para: arrecadacaoPara,
					grupos_unidade: arrecadacaoPara !== '1' ? [] : gruposUnidade || [],
					ids_unidades: arrecadacaoPara !== '2' ? [] : idsUnidades || [],
					tipos_unidades: arrecadacaoPara !== '4' ? [] : tiposUnidades || [],
					config_unidades_arrecadacao: arrecadacaoPara !== '5' ? [] : configUnidadesArrecadacao || [],
				},
			})
			.then(() => {
				notify('Arrecadação alterada com sucesso');
				refresh();
				try {
					carregaValoresCB()();
				} catch (e) {}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notify(Object.values(e?.response?.data || {})[0] || 'Erro inesperado', 'warning', {}, false, 10000);
			});
	};

	return (
		<CustomDialogBody
			title='Editar Arrecadação'
			form={{
				handleSubmit,
				valid,
				component: (
					<Box>
						{contaReceita?.id && (
							<>
								<BoxAddConta
									{...{
										contaReceita,
										setContaReceita,
										tipo: tipo(),
									}}
								/>
								<Box height='15px' />
							</>
						)}
						{arrecadacaoPara === '5' && (
							<BoxButtonModalListaUnidade
								{...{
									setValor,
									configUnidadesArrecadacao,
									setConfigUnidadesArrecadacao,
								}}
							/>
						)}
						<BoxValorDesc
							{...{
								percentual: record.tipo_lancamento === 'PRC',
								arrecPara: arrecadacaoPara,
								desc,
								setDesc,
								valor,
								setValor,
							}}
						/>
						{['RECEITA', 'DESPESA', 'FUNDO', 'DESCONTO'].includes(tipoArrecadacao) ? (
							<>
								<BoxArrecadacaoParaTipoDivisao
									{...{
										arrecadacaoPara,
										gruposUnidade,
										idsUnidades,
										record,
										setArrecadacaoPara,
										setIdsUnidades,
										setTipoDivisao,
										setTiposUnidades,
										setGruposUnidade,
										tipoArrecadacao,
										tipoDivisao,
										tiposUnidades,
									}}
								/>
								<Box height='20px' />
							</>
						) : null}
						{record?.tipo_lancamento === 'PRC' && Boolean(contaReceita) && (
							<Box pt='15px' display='grid'>
								<BoxAddContasCalc
									{...{
										contaReceita,
										contasDespCalc,
										contasRecCalc,
										setContasDespCalc,
										setContasRecCalc,
									}}
									arrecadacao_id={record.id}
								/>
							</Box>
						)}
					</Box>
				),
			}}
		/>
	);
};

export const ModalConfirmacaoRemocao = ({ record }) => {
	const { carregaValoresCB } = useContext(ArrecadacoesContext);
	const dataProvider = useDataProvider();
	const notifty = useNotify();
	const refresh = useRefresh();
	const {
		palette: { syndikosRed },
	} = useTheme();
	const handleSubmit = () => {
		dataProvider
			.delete('arrecadacao', { id: record.id })
			.then(() => {
				notifty('Arrecadação removida com sucesso');
				refresh();
				try {
					carregaValoresCB()();
				} catch (e) {}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notifty(Object.values(e?.response?.data || {})[0] || 'Erro inesperado', 'warning');
			});
	};
	return (
		<CustomDialogBody
			remove
			title={`Tem certeza que deseja remover arrecadação "${record.complemento}"?`}
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							Essa ação é irreversível.
							<br />
							Todo o histórico será excluído.
						</Typography>
					</Box>
				</Box>
			}
			form={{ handleSubmit, valid: true }}
		/>
	);
};

export const ModalConfirmacaoAtualizacao = ({ record }) => {
	const { carregaValoresCB } = useContext(ArrecadacoesContext);
	const dataProvider = useDataProvider();
	const notifty = useNotify();
	const refresh = useRefresh();
	const {
		palette: { syndikosRed },
	} = useTheme();
	const handleSubmit = () => {
		dataProvider
			.update('arrecadacao', { id: record.id })
			.then(() => {
				notifty('Arrecadação alterada com sucesso');
				refresh();
				try {
					carregaValoresCB()();
				} catch (e) {}
			})
			.catch((e) => {
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				notifty(Object.values(e?.response?.data || {})[0] || 'Erro inesperado', 'warning');
			});
	};
	return (
		<CustomDialogBody
			customSubmitLabel='Atualizar'
			title={`Tem certeza que deseja alterar arrecadação "${record.complemento}"?`}
			text={
				<Box display='flex' alignItems='center' gridGap='20px'>
					<Box>
						<WarningIcon style={{ color: syndikosRed.main }} fontSize='large' />
					</Box>
					<Box>
						<Typography
							style={{
								fontWeight: 'bold',
								color: syndikosRed.main,
							}}
						>
							Essa ação é irreversível.
							<br />
							Todo o histórico será excluído.
						</Typography>
					</Box>
				</Box>
			}
			form={{ handleSubmit, valid: true }}
		/>
	);
};
