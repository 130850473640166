import React, { useContext, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import { NumberFormatBRL, sanitizeListNumberFormatBRLProps } from '../../../common/CurrencyInput';
import VencimentoField from '../VencimentoField';
import { FormEditarParcelaContext } from './FormEditarParcelaContext';
import InputFile from '../../../common/InputFile';

const DataVencimentoField = () => {
	const {
		vencimentoBase: value,
		setVencimentoBase: setValue,
		erros,
		setErros,
	} = useContext(FormEditarParcelaContext);

	return (
		<VencimentoField
			{...{
				value,
				setValue,
				erros,
				setErros,
				erroField: 'data_vencimento_base',
				fullWidth: false,
			}}
		/>
	);
};

const PrevisaoPagamentoField = () => {
	const { vencimento: value, setVencimento: setValue, erros, setErros } = useContext(FormEditarParcelaContext);

	return (
		<VencimentoField
			{...{
				value,
				setValue,
				erros,
				setErros,
				erroField: 'data_vencimento',
				fullWidth: false,
				label: 'Previsão de Pagamento',
			}}
		/>
	);
};

const ValorField = ({
	label = 'Valor',
	value = 0,
	onChange = () => {},
	disabled = false,
	isRequired = false,
	error,
	inputProps = { prefix: 'R$ ' },
}) => (
	<TextField
		size='small'
		margin='dense'
		{...{
			label,
			value,
			disabled,
			onChange,
			isRequired,
		}}
		inputProps={{
			...sanitizeListNumberFormatBRLProps(inputProps),
			style: { textAlign: 'right' },
		}}
		InputProps={{
			inputComponent: NumberFormatBRL,
		}}
		error={error}
		helperText={error}
		fullWidth
	/>
);

const ValorPendenteField = () => {
	const { valorPendente, setValorPendente, erros, setErros, valorDisponivel } = useContext(FormEditarParcelaContext);

	const validate = () => {
		if (typeof valorPendente !== 'number' && !valorPendente) {
			setErros((e) => ({
				...e,
				valor_pendente: 'Forneça o valor da parcela',
			}));
		} else if (erros?.valor_pendente) {
			setErros((e) => {
				const { valor_pendente, ...rest } = e;
				return rest;
			});
		}
	};
	useEffect(validate, [valorPendente]);

	return (
		<ValorField
			label='Valor'
			value={valorPendente}
			onChange={(event) => {
				const newValue = event?.target?.value || 0;
				setValorPendente(valorDisponivel > newValue ? newValue : valorDisponivel);
			}}
			error={erros?.valor_pendente}
			isRequired={true}
		/>
	);
};

const PorcentagemField = () => {
	const { valorPorcentagem } = useContext(FormEditarParcelaContext);

	return <ValorField label='Porcentagem' value={valorPorcentagem} disabled={true} inputProps={{ suffix: '%' }} />;
};

const FormEditarParcela = () => {
	const { setAnexoBoleto, anexoBoleto, setAnexoComprovante, anexoComprovante, setLinhaOuCodigo, linhaOuCodigo } =
		useContext(FormEditarParcelaContext);
	const [erro, setErro] = React.useState(false);
	const handleLinhaDigitavel = (e) => {
		const regex_sem_letras = /^[0-9 .]*$/;
		const regex_espacos_consecutivos = /\s{2,}/;

		if (regex_sem_letras.test(e.target.value) && !regex_espacos_consecutivos.test(e.target.value)) {
			setLinhaOuCodigo(e.target.value);
		}
	};
	React.useEffect(() => {
		if (linhaOuCodigo?.length >= 44) {
			setErro(false);
		}
	}, [linhaOuCodigo]);

	return (
		<Box display='flex'>
			<Box flex={1}>
				<Box display='flex'>
					<Box flex={1} mr='1em' mt='8px'>
						<PrevisaoPagamentoField />
					</Box>
					<Box flex={1} mr='1em' mt='8px'>
						<DataVencimentoField />
					</Box>
					<Box flex={1} mr='1em'>
						<ValorPendenteField />
					</Box>
					<Box flex={1} mr='1em'>
						<PorcentagemField />
					</Box>
				</Box>
				<Box display='flex' mt='10px'>
					<Box flex={1} mr='1em'>
						<TextField
							value={linhaOuCodigo}
							fixedDecimalScale={true}
							onChange={handleLinhaDigitavel}
							label='Informe o Código de Barras ou a Linha Digitável'
							size='small'
							fullWidth
							inputProps={{ maxLength: 54 }}
							onBlur={() =>
								linhaOuCodigo?.length < 44 && linhaOuCodigo != '' ? setErro(true) : setErro(false)
							}
							error={erro}
							helperText={erro ? 'O campo Linha digitável/Código de barras está incorreto' : ''}
						/>
					</Box>
				</Box>
				<Box display='flex' mt='15px'>
					<Box flex={1} mr='1em'>
						<InputFile
							label='Boleto'
							onCallBack={(e, dataUrl) => setAnexoBoleto(dataUrl)}
							fileDataUrl={anexoBoleto}
						/>
					</Box>
				</Box>
				<Box display='flex' mt='10px'>
					<Box flex={1} mr='1em'>
						<InputFile
							label='Comprovante de pagamento'
							onCallBack={(e, dataUrl) => setAnexoComprovante(dataUrl)}
							fileDataUrl={anexoComprovante}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default FormEditarParcela;
