import React from 'react';

import memoize from 'components/common/memoize';

import PadraoTabInstrucoesCobrancaRemessaBase, {
	MultaFields,
	JurosFields,
	DescontoFields,
	ProtestoFields,
	getPropsOnChangeTipoPadraoProtesto,
	createOnChangeTipoPadraoProtesto,
} from '../../../tabs/TabInstrucoesCobrancaRemessa';

import {
	formatCedentePattern2,
	getDadosFormattingContaCorrente,
	parseCedentePattern2,
	formatCedentePattern1,
	parseCedentePattern1,
} from '../../../utils/formaters_parses';

import { getFormatarOptions } from '../../../utils/functions';

import DefaultContaCorrenteProps, {
	defaultComponentesProps,
	defaultFormatadoresPayloadValues,
	defaultFormatadoresPayloadValuesCobrancaConfig,
	formataPayloadValuesTabContaCobrancaConfig,
	formataPayloadValuesMulta,
	formataPayloadValuesJuros,
	formataPayloadValuesDesconto,
	formataPayloadValuesProtesto,
	getFormatadorPayloadCobrancaConfig,
	getPadraoPayloadValuesInstrucoesRemessa,
	getValidateQuandoEmitindoBoletos,
	getValidacoesMulta,
	getValidacoesJuros,
	getValidacoesDesconto,
	getValidacoesProtesto,
	mensagensErrosPadroes,
} from '../../default/ContaCorrenteProps';

import TabInformacao from '../../../tabs/TabInformacao';
import TabEmissaoBoletos from '../../../tabs/TabEmissaoBoletos';
import TabConta from './componentes/TabConta';
import TabInstrucoesCobrancaBoleto from './componentes/TabInstrucoesCobrancaBoleto';
import TabInstrucoesCobrancaRemessaAutomatico from './componentes/TabInstrucoesCobrancaRemessa';
import ModalCadastrarDadosIntegracaoAPI from './componentes/TabConta/ModalCadastrarDadosIntegracaoAPI';

import {
	createOnChangeTipoPadraoMulta,
	getPropsOnChangeTipoPadraoMulta,
} from './componentes/TabInstrucoesCobrancaRemessa/MultaFields';

import {
	createOnChangeTipoPadraoJuros,
	getPropsOnChangeTipoPadraoJuros,
} from './componentes/TabInstrucoesCobrancaRemessa/JurosFields';

import {
	createOnChangeTipoPadraoDesconto,
	getPropsOnChangeTipoPadraoDesconto,
} from './componentes/TabInstrucoesCobrancaRemessa/DescontoFields';

import { formatarInitialValuesAutomaticoOuManual as formatarInitialValues } from './formatarInitialValues';
import getFormataPayloadValues from './getFormataPayloudValues';
import getValidate from './getValidate';
import validacoes from './validacoes';
import formatadoresPayloadValues from './formatadoresPayloadValues';

const getExtras = ({
	props,
	instituicaoCodigo,
	initialDados: {
		opcoesDadosBancarios: { integracoes },
	},
}: any) => ({
	optionsIsencoes: integracoes[instituicaoCodigo].optionsIsencoes,
	...props,
});

const validacoesMulta = getValidacoesMulta({ minValue: 1 });

const validacoesJuros = getValidacoesJuros({ minValue: 0 });

const validacoesDesconto = getValidacoesDesconto();

const validacoesProtesto = getValidacoesProtesto({
	nomeCampoLegivel: 'protesto/negativação',
	msgErroVazio: mensagensErrosPadroes.vazioProtesto({ nomeCampoLegivel: 'protesto/negativação' }),
	minValue: 3,
	msgErroMinValue: mensagensErrosPadroes.minValueProtesto({
		nomeCampoLegivel: 'protesto/negativação',
		minValue: 3,
	}),
	maxValue: 99,
	msgErroMaxValue: mensagensErrosPadroes.maxValueProtesto({
		nomeCampoLegivel: 'protesto/negativação',
		maxValue: 99,
	}),
});

const validacoesInstrucoesCobrancaRemessa = [
	validacoesMulta.tipo,
	validacoesMulta.valor,
	validacoesMulta.dias,

	validacoesJuros.tipo,
	validacoesJuros.valor,
	validacoesJuros.dias,

	validacoesDesconto.tipo,
	validacoesDesconto.valor,
	validacoesDesconto.dias,

	validacoesProtesto.tipo,
	validacoesProtesto.dias,
];

const formataPayloadValuesTabInstrucoesCobrancaRemessa = ({ values }: any) => ({
	...getPadraoPayloadValuesInstrucoesRemessa(),
	...formataPayloadValuesMulta({ values }),
	...formataPayloadValuesJuros({ values }),
	...formataPayloadValuesDesconto({ values }),
	...formataPayloadValuesProtesto({ values }),
});

const TabInstrucoesCobrancaRemessa = (props: any) => (
	<PadraoTabInstrucoesCobrancaRemessaBase
		{...props}
		ColunaDireitaComponentes={[<MultaFields />, <JurosFields />, <DescontoFields />]}
		ColunaEsquerdaComponentes={[<ProtestoFields />]}
	/>
);

const getDisabledTabs = memoize(({ values: { emitir_boletos } }: any) => {
	return {
		disabled0: false,
		disabled1: false,
		disabled2: !emitir_boletos,
		disabled3: !emitir_boletos,
		disabled4: !emitir_boletos,
	};
});

const ContaCorrenteProps = {
	...DefaultContaCorrenteProps,
	instituicaoCodigo: '403',
	getOptions: getFormatarOptions('403'),
	getDisabledTabs,
	getValidate,
	tabContents: {
		0: {
			TabInformacao,
			TabEmissaoBoletos,
			TabConta,
			TabInstrucoesCobrancaRemessa: TabInstrucoesCobrancaRemessaAutomatico,
			TabInstrucoesCobrancaBoleto,
		},
		1: {
			...DefaultContaCorrenteProps.tabContents,
			TabInstrucoesCobrancaRemessa,
		},
	},
	validacoes: {
		0: validacoes,
		1: [
			...DefaultContaCorrenteProps.validacoes,
			getValidateQuandoEmitindoBoletos(validacoesInstrucoesCobrancaRemessa),
		],
	},
	props: {
		0: {
			tipo_conta: {
				source: 'tipo_conta_bancaria',
				name: 'tipo_conta_bancaria',
				id: 'tipo_conta_bancaria',
				choices: [{ id: 'CC', text: 'Conta Corrente' }],
			},
			conta_cedente: {
				identificador: 0,
				parse: parseCedentePattern1(5),
				format: formatCedentePattern1(5),
			},
			multa: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoMulta,
				createOnChangeTipo: createOnChangeTipoPadraoMulta,
			},
			juros: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoJuros,
				createOnChangeTipo: createOnChangeTipoPadraoJuros,
			},
			desconto: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoDesconto,
				createOnChangeTipo: createOnChangeTipoPadraoDesconto,
			},
			protesto: {
				getPropsOnChangeTipo: getPropsOnChangeTipoPadraoProtesto,
				createOnChangeTipo: createOnChangeTipoPadraoProtesto,
			},
			CadastrarDadosIntegracaoAPIButton: {
				ModalCadastrarDadosIntegracaoAPI,
				getInicialCallback: ({ errorsMsg, ...inicialDados }: any) => ({
					...inicialDados,
					errorsMsg,
					initialValues: {
						client_id: '',
						client_secret: '',
					},
					values: {
						client_id: '',
						client_secret: '',
					},
					errors: {
						client_id: errorsMsg.client_id.required,
						client_secret: errorsMsg.client_secret.required,
					},
				}),
			},
		},
		1: {
			...defaultComponentesProps,
			conta_corrente: {
				...getDadosFormattingContaCorrente(6),
			},
			conta_cedente: {
				identificador: 1,
				parse: parseCedentePattern2(5, 1),
				format: formatCedentePattern2(5, 1),
			},
			multa_dias: { min: 1 },
			protesto_tipo: {
				label: 'Protesto/Negativação',
			},
			protesto_dias: { min: 3, max: 99 },
		},
	},
	getFormataPayloadValues,
	formatarInitialValues,
	formatadoresPayloadValues: {
		0: { ...formatadoresPayloadValues },
		1: {
			...defaultFormatadoresPayloadValues,
			cobranca_config: getFormatadorPayloadCobrancaConfig({
				formatadores: [
					...defaultFormatadoresPayloadValuesCobrancaConfig,
					// Dados de Tab Conta
					formataPayloadValuesTabContaCobrancaConfig,
					// Dados de Tab Instruções de Cobrança Remessa
					formataPayloadValuesTabInstrucoesCobrancaRemessa,
				],
			}),
		},
	},
	getExtras,
};

export default ContaCorrenteProps;
