import React, { memo, useCallback, useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { FunctionField, List, Pagination, ReferenceField, TextField, useListContext } from 'react-admin';

import { IconButton, Menu, MenuItem, TextField as TextFieldMUI, Box, Typography } from '@material-ui/core';
import { DateRangePicker } from 'materialui-daterange-picker-pt';

import { sub, add, format, parseISO, set } from 'date-fns';
import brExporter from '../../../utils/exporter';
import { Tabela } from '../../common/Tabela';
import { Padrao } from '../../common/filtros/Padrao';
import { Autocompletar } from '../../common/filtros/Autocompletar';
import { ModalPeriodoPickerWrapper } from '../../common/PeriodoSelect';
import { enqueteSituacaoOpcoes, tipoResponder } from 'fieldControl/enquete';
import { CondominiosContext } from 'context/CondominioContextProvider';
import SurveyExcludeModal from './modals/SurveyExcludeModal';
import SurveyEndVotingModal from './modals/SurveyEndVotingModal';
import SurveyResultModal from './modals/SurveyResultModal';
import SurveyDetailsModal from './modals/SurveyDetailsModal';
import { ModalContextProvider, CustomDialog, ModalContext } from 'components/common/ModalContext';

import {
	Visibility as VisibilityIcon,
	Create as CreateIcon,
	Cancel as CancelIcon,
	BarChart as BarChartIcon,
	Delete as DeleteIcon,
	ListAlt as ListAltIcon,
	ArrowDropDown as ArrowDropDownIcon,
} from '@material-ui/icons';

const TODAY = new Date();
TODAY.setHours(10, 0, 0);
const MINDATE = new Date(1990, 0, 1).setHours(10, 0, 0);

const formatDate = (date) => {
	if (!date || date.toString() === 'Invalid Date') return '';
	return format(date, 'dd/MM/yyyy');
};

const periodoOptions = [
	{
		label: 'Limpar',
		startDate: undefined,
		endDate: undefined,
	},
	{
		label: 'Mês atual',
		startDate: set(TODAY, { date: 1 }),
		endDate: sub(add(set(TODAY, { date: 1 }), { months: 1 }), { days: 1 }),
	},
	{
		label: 'Hoje',
		startDate: TODAY,
		endDate: TODAY,
	},
	{
		label: 'Ontem',
		startDate: sub(TODAY, { days: 1 }),
		endDate: sub(TODAY, { days: 1 }),
	},
	{
		label: 'Últimos 3 dias',
		startDate: sub(TODAY, { days: 3 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 5 dias',
		startDate: sub(TODAY, { days: 5 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 7 dias',
		startDate: sub(TODAY, { days: 7 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 15 dias',
		startDate: sub(TODAY, { days: 15 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 30 dias',
		startDate: sub(TODAY, { days: 30 }),
		endDate: TODAY,
	},
	{
		label: 'Últimos 60 dias',
		startDate: sub(TODAY, { days: 60 }),
		endDate: TODAY,
	},
	{
		label: 'Próximos 15 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 15 }),
	},
	{
		label: 'Próximos 30 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 30 }),
	},
	{
		label: 'Próximos 60 dias',
		startDate: TODAY,
		endDate: add(TODAY, { days: 60 }),
	},
];

const PeriodoPicker = memo(({ dateRange, open, setOpen, setDateRange }) => {
	const toggle = () => setOpen(!open);
	return (
		<ModalPeriodoPickerWrapper {...{ open, setOpen }}>
			<DateRangePicker
				open={true}
				toggle={toggle}
				onChange={(range) => setDateRange(range)}
				initialDateRange={
					dateRange?.startDate &&
					typeof dateRange.startDate !== 'number' &&
					dateRange.startDate.toString() === 'Invalid Date'
						? {
								label: 'custom',
								startDate: dateRange.startDate.setHours(10, 0, 0) && dateRange.startDate,
								endDate: dateRange.endDate.setHours(10, 0, 0) && dateRange.endDate,
						  }
						: dateRange
				}
				minDate={MINDATE}
				definedRanges={periodoOptions}
			/>
		</ModalPeriodoPickerWrapper>
	);
});

const PeriodoFilter = memo(({ source }) => {
	const { setFilters, filterValues } = useListContext();
	const [open, setOpen] = useState(false);
	const [dateRange, setDateRange] = useState(
		filterValues && filterValues[`${source}_after`] && filterValues[`${source}_before`]
			? {
					label: 'custom',
					startDate: parseISO(filterValues[`${source}_after`]).setHours(10, 0, 0),
					endDate: parseISO(filterValues[`${source}_before`]).setHours(10, 0, 0),
			  }
			: periodoOptions[0]
	);

	const handleRangeChange = useCallback(() => {
		const startDate = dateRange?.startDate ? format(dateRange.startDate, 'yyyy-MM-dd') : undefined;
		const endDate = dateRange?.endDate ? format(dateRange.endDate, 'yyyy-MM-dd') : undefined;
		if (filterValues[`${source}_after`] !== startDate || filterValues[`${source}_before`] !== endDate) {
			setFilters({
				...filterValues,
				[`${source}_after`]: startDate,
				[`${source}_before`]: endDate,
			});
		}
	}, [setFilters, filterValues, source, dateRange]);

	useEffect(handleRangeChange, [dateRange]);

	return (
		<>
			<TextFieldMUI
				onClick={() => setOpen(true)}
				fullWidth
				inputProps={{
					value: `${dateRange?.endDate ? `de ${formatDate(dateRange?.startDate)}` : ''} ${
						dateRange?.endDate ? `até ${formatDate(dateRange?.endDate)}` : ''
					}`,
				}}
				variant='standard'
				margin='normal'
				style={{ marginBottom: 0 }}
			/>
			<PeriodoPicker {...{ dateRange, open, setOpen, setDateRange }} />
		</>
	);
});

const DateField = (props) => (
	<span>{props?.record[props?.source] ? format(parseISO(props?.record[props?.source]), 'dd/MM/yyyy') : ''}</span>
);

const SituacaoField = ({ record }) => {
	let situacaoTexto;
	switch (record.situacao) {
		case 'E':
			situacaoTexto = 'Em andamento';
			break;
		case 'F':
			situacaoTexto = 'Encerrada';
			break;
		default:
			situacaoTexto = 'Indefinido';
	}
	return <span>{situacaoTexto}</span>;
};

const ActionMenu = ({ record }) => {
	const router = useHistory();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const { setModalValue } = React.useContext(ModalContext);

	return (
		<div>
			<IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
				<ArrowDropDownIcon />
			</IconButton>
			<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
				{record.situacao === 'F' ? (
					<MenuItem onClick={() => router.push(`/enquetes/${record.id}`)}>
						<Box display='flex' gridGap='0.7em'>
							<VisibilityIcon color='primary' />
							<Typography>Visualizar</Typography>
						</Box>
					</MenuItem>
				) : (
					<MenuItem onClick={() => router.push(`/enquetes/${record.id}`)}>
						<Box display='flex' gridGap='0.7em'>
							<CreateIcon color='primary' />
							<Typography>Editar</Typography>
						</Box>
					</MenuItem>
				)}
				{record.situacao !== 'F' && (
					<MenuItem
						onClick={() => {
							setModalValue((v) => ({
								...v,
								open: true,
								dialogBody: (
									<SurveyEndVotingModal
										surveyId={record.id}
										surveyAttachment={record.anexo_enquete}
									/>
								),
							}));
						}}
						disabled={record.situacao === 'F'}
					>
						<Box display='flex' gridGap='0.7em'>
							<CancelIcon color='primary' />
							<Typography>Encerrar votação</Typography>
						</Box>
					</MenuItem>
				)}
				<MenuItem
					onClick={() =>
						setModalValue((v) => ({
							...v,
							open: true,
							dialogBody: <SurveyResultModal surveyId={record.id} />,
						}))
					}
				>
					<Box display='flex' gridGap='0.7em'>
						<BarChartIcon color='primary' />
						<Typography>Resultado da Enquete</Typography>
					</Box>
				</MenuItem>
				<MenuItem
					onClick={() => {
						setModalValue((v) => ({
							...v,
							open: true,
							dialogBody: <SurveyDetailsModal surveyId={record.id} />,
						}));
					}}
				>
					<Box display='flex' gridGap='0.7em'>
						<ListAltIcon color='primary' />
						<Typography>Detalhes da Enquete</Typography>
					</Box>
				</MenuItem>
				<MenuItem
					onClick={() =>
						setModalValue((v) => ({
							...v,
							open: true,
							dialogBody: <SurveyExcludeModal surveyId={record.id} />,
						}))
					}
				>
					<Box display='flex' gridGap='0.7em'>
						<DeleteIcon color='error' />
						<Typography>Excluir</Typography>
					</Box>
				</MenuItem>
			</Menu>
		</div>
	);
};

const ResponderField = ({ record }) => (
	<span>
		{record.quem_pode_responder === 'I'
			? 'Inquilino'
			: record.quem_pode_responder === 'P'
			? 'Proprietário'
			: record.quem_pode_responder === 'A'
			? 'Ambos'
			: record.quem_pode_responder === 'R'
			? 'Responsável pela Cobrança'
			: 'Valor Desconhecido'}
	</span>
);

const TabelaEnquetes = (props) => {
	const {
		filterValues: { id_condominio, ...rest },
		setFilters,
	} = useListContext();
	const { setCondominioPorId, condominioSelecionado } = useContext(CondominiosContext);

	const initCondominio = () => {
		if (condominioSelecionado?.id && id_condominio !== condominioSelecionado?.id) {
			setFilters({ ...rest, id_condominio: condominioSelecionado?.id });
		}
	};
	useEffect(initCondominio, []);

	const changeCondominio = () => {
		if (id_condominio && id_condominio !== condominioSelecionado?.id) {
			setCondominioPorId(id_condominio);
		}
	};
	useEffect(changeCondominio, [id_condominio]);
	return (
		<Tabela rowClick={false} disableClickRow={true} {...props}>
			<FunctionField render={(record) => <ActionMenu record={record} />} label='Ações' />

			<ReferenceField
				id='reference-field-condominio'
				source='condominio'
				reference='condominios'
				label='Condomínio'
				link={false}
				sortBy='nome_condominio'
				filtro={
					<Autocompletar
						refName='nome'
						refResource='condominios'
						mostrarInativos={false}
						id='filtro-nome-condominio'
					/>
				}
				minWidth='200px'
			>
				<TextField source='nome' />
			</ReferenceField>
			<ReferenceField
				source='condominio'
				reference='condominios'
				label='Apelido'
				id='tabela-row-apelido-condominio'
				link={false}
				filtro={
					<Autocompletar
						refName='apelido'
						refResource='condominios'
						mostrarInativos={false}
						id='filtro-apelido-condominio'
					/>
				}
				minWidth='200px'
			>
				<TextField source='apelido' />
			</ReferenceField>
			<DateField source='finaliza_em' label='Válida até' filtro={<PeriodoFilter />} minWidth='200px' />
			<TextField source='titulo' label='Título' filtro={<Padrao />} />
			<ResponderField
				source='quem_pode_responder'
				label='Quem pode responder?'
				filtro={<Autocompletar opcoes={tipoResponder} />}
			/>

			<SituacaoField source='situacao' label='Status' filtro={<Autocompletar opcoes={enqueteSituacaoOpcoes} />} />
		</Tabela>
	);
};

export const EnqueteList = (props) => {
	const { condominioSelecionado } = useContext(CondominiosContext);

	return (
		<List
			{...props}
			exporter={brExporter}
			empty={false}
			bulkActionButtons={false}
			title='Enquete'
			perPage={10}
			pagination={
				<Pagination
					rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
					labelRowsPerPage='Enquetes por página'
				/>
			}
			filterDefaultValues={{
				id_condominio: condominioSelecionado?.id,
				situacao: 'E',
				situacao_condominio: 'A',
			}}
			sort={{ field: 'condominio', order: 'ASC' }}
		>
			<ModalContextProvider>
				<TabelaEnquetes />
				<CustomDialog backdropClick={false} />
			</ModalContextProvider>
		</List>
	);
};
