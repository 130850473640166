import lodashMemoize from 'lodash/memoize';
import api from '../services/api';

const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args));

const verificacao = async (value, resource, customQuery) => {
	if (value && value.length >= 11) {
		if (customQuery) {
			try {
				const res = await api.get(`api/v1/${resource}?${customQuery}=${value}`);
				if (res.status === 200 && res.data?.count > 0)
					return [`${value.length === 11 ? 'CPF' : 'CNPJ'} já cadastrado`];
			} catch {}
		} else {
			try {
				const res = await api.get(`api/v1/${resource}/${value}/buscar_documento`);
				if (res.status === 200) return [`${value.length === 11 ? 'CPF' : 'CNPJ'} já cadastrado`];
			} catch {}
		}
	}
};

export const cpfValidacao = (value, resource, documentoInicial, customQuery) => {
	if (value) {
		value = cpfParser(value);
		if (!value.match(/[0-9]+/)) return ['CPF Inválido'];
		if (!Array.from(value).filter((e) => e !== value[0]).length) {
			return ['CPF Inválido'];
		}
		let soma = 0;
		let resto;
		for (let i = 1; i <= 9; i++) soma += parseInt(value.substring(i - 1, i)) * (11 - i);
		resto = (soma * 10) % 11;
		if (resto === 10 || resto === 11) resto = 0;
		if (resto !== parseInt(value.substring(9, 10))) return ['CPF Inválido'];

		soma = 0;
		for (let i = 1; i <= 10; i++) soma += parseInt(value.substring(i - 1, i)) * (12 - i);
		resto = (soma * 10) % 11;
		if (resto === 10 || resto === 11) resto = 0;
		if (resto !== parseInt(value.substring(10, 11))) return ['CPF Inválido'];

		if (resource && (!documentoInicial || (documentoInicial && value !== documentoInicial)))
			return verificacao(value, resource, customQuery);
	}
};

export const cpfFormatacao = memoize((value) => {
	if (!value) return null;
	if (!value.match(/[0-9]+/)) return null;
	return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
});

export const cpfParser = memoize((value) => {
	if (value) {
		return value.replace(/[^\d]/g, '').substring(0, 11);
	}
});

export const cnpjValidacao = (value, resource, documentoInicial, customQuery) => {
	if (value) {
		value = cnpjParser(value);
		if (value.length !== 14) return ['CNPJ Inválido'];

		const match = value.toString().match(/\d/g);
		const numbers = Array.isArray(match) ? match.map(Number) : [];

		if (numbers.length !== 14) return ['CNPJ Inválido'];

		const items = [...new Set(numbers)];
		if (items.length === 1) return ['CNPJ Inválido'];

		const calc = (x) => {
			const slice = numbers.slice(0, x);
			let factor = x - 7;
			let sum = 0;

			for (let i = x; i >= 1; i--) {
				const n = slice[x - i];
				sum += n * factor--;
				if (factor < 2) factor = 9;
			}

			const result = 11 - (sum % 11);

			return result > 9 ? 0 : result;
		};

		const digits = numbers.slice(12);

		const digit0 = calc(12);
		if (digit0 !== digits[0]) return ['CNPJ Inválido'];

		const digit1 = calc(13);
		if (digit1 !== digits[1]) return ['CNPJ Inválido'];

		if (resource && (!documentoInicial || (documentoInicial && value !== documentoInicial)) && value?.length > 13)
			return verificacao(value, resource, customQuery);
	}
};

export const cnpjFormatacao = memoize((value) => {
	if (!value) return null;
	if (!value.match(/[0-9]+/)) return null;
	return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
});

export const cnpjParser = memoize((value) => {
	if (value) {
		return value.replace(/[^\d]/g, '').substring(0, 14);
	}
});
