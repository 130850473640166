import React from 'react';
import { Typography, Box, Checkbox } from '@material-ui/core';

import {
	CustomDialogBodySizeEditable,
	ModalSizeEditableContextProvider,
} from 'components/common/ModalSizeEditableContext';
import ToolInputSelect, { ToolInput } from 'components/common/commonComponentsTSX/inputs/Toolinput';
import { RawKBDatePicker } from 'components/common/InputDate';

import BoxPessoa from '../BoxPessoa';
import { PessoasContext } from 'context/PessoaContextProvider';
import { TFuncao, TFuncionario } from '../types';
import dataProvider from 'dataProvider';
import { useNotify } from 'react-admin';
import { CondominiosContext } from 'context/CondominioContextProvider';
import { FuncionarioContext } from '../contexts/FuncionarioContext';

export const ModalCriaFuncionario: React.FC = React.memo(() => {
	const { setFuncionarios, condominioId } = React.useContext(FuncionarioContext);
	const { pessoaSelecionada, setPessoaSelecionada } = React.useContext(PessoasContext);

	const [funcao, setFuncao] = React.useState<TFuncao | null>(null);
	const [dataEntrada, setDataEntrada] = React.useState<Date>(new Date());
	const [dataSaida, setDataSaida] = React.useState<Date | null>(null);
	const [acessoApp, setAcessoApp] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const notify = useNotify();

	const handleSetFuncaoId = (funcao: TFuncao) => {
		if (funcao?.id) setFuncao(funcao);
	};

	const handleCriaFuncionario = () => {
		if (loading) return;
		setLoading(true);
		dataProvider
			.create('funcionarios', {
				data: {
					pessoa: pessoaSelecionada?.id,
					funcao: funcao?.id,
					data_entrada: dataEntrada.toISOString().split('T')[0],
					data_saida: dataSaida ? dataSaida.toISOString().split('T')[0] : null,
					condominio: condominioId,
					acesso_app: acessoApp,
				},
			})
			.then((res) => {
				if (!pessoaSelecionada) return;
				const novoFuncionario: TFuncionario = {
					id: res.data.id,
					pessoa: pessoaSelecionada,
					dataEntrada: dataEntrada.toISOString().split('T')[0],
					dataSaida: dataSaida ? dataSaida.toISOString().split('T')[0] : undefined,
					funcao: funcao ? funcao : ({} as TFuncao),
					acessoApp: acessoApp,
				};
				setFuncionarios((prev) => [...prev, novoFuncionario]);
				setFuncao(null);
				setPessoaSelecionada(undefined);

				notify('Cadastro realizado com sucesso', { type: 'success' });
			})
			.catch((e) => {
				setLoading(false);
				if ([401, 403].includes(e?.response?.status)) return Promise.reject(e);
				return notify('Ocorreu um problema ao realizar o cadastro.', { type: 'error' });
			});
	};

	return (
		<>
			<CustomDialogBodySizeEditable
				title={'Cadastrar Funcionário'}
				closeInSubmit={true}
				DialogContentCustomStyle={{ overflow: 'hidden' }}
				form={{
					valid:
						pessoaSelecionada && funcao?.id && dataEntrada && (dataSaida ? dataEntrada < dataSaida : true),
					handleSubmit: handleCriaFuncionario,
					component: (
						<>
							<Box>
								<BoxPessoa />
							</Box>
							<Box display='flex' width='100%' paddingTop='5px'>
								<Box paddingRight='5px' width='100%'>
									<RawKBDatePicker
										format='dd/MM/yyyy'
										fullWidth
										margin='dense'
										variant='inline'
										autoOk
										label='Data de Entrada*'
										value={dataEntrada}
										onChange={setDataEntrada}
									/>
								</Box>
								<Box paddingLeft='5px' width='100%'>
									<RawKBDatePicker
										format='dd/MM/yyyy'
										fullWidth
										margin='dense'
										variant='inline'
										label='Data de Saída'
										value={dataSaida}
										onChange={setDataSaida}
									/>
								</Box>
							</Box>
							<Box display='flex'>
								<Box display='flex' width='100%' paddingRight='5px'>
									<ToolInputSelect
										ToolInput={
											<ToolInput
												getOptionsSelected={handleSetFuncaoId}
												optionText='descricao'
												label='função'
												TextFieldProps={{ fullWidth: true, size: 'small' }}
												defaultValueId={funcao?.id}
											></ToolInput>
										}
										optionText='descricao'
										source='funcoes_funcionarios'
									/>
								</Box>
								<Box width='100%' display='flex' alignItems='center' paddingLeft='5px'>
									<Checkbox
										checked={acessoApp}
										onClick={() => {
											setAcessoApp(!acessoApp);
										}}
									/>
									<Typography>Acesso ao App</Typography>
								</Box>
							</Box>
						</>
					),
				}}
				customActions={null}
			/>
		</>
	);
});
