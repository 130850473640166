import React, { useState, useEffect } from 'react';

import { Box } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { format, add, parse, isValid, isAfter, isBefore, isEqual, endOfMonth } from 'date-fns';

import { BoxDivisor } from '../../../common/Formulario';
import { RelatorioInputDate, TODAY } from '../dateUtils';

import RelatorioToolbar from '../RelatorioToolbar';
import { CheckboxRelatorioContext } from '../checkbox/CheckboxRelatorioContextProvider';

const OpcoesImpressaoMensalCheckboxGroup = ({
	handleChange,
	apenasAcumulados,
	imprimirAcumulado,
	imprimirMedia,
	imprimirDevedores,
	imprimirAssinatura,
	receitaBruta,
}) => {
	return (
		<FormControl component='fieldset'>
			<FormLabel component='legend'>Opções de Impressão</FormLabel>
			<FormGroup row fullWidth>
				<FormControlLabel
					control={
						<Checkbox
							checked={apenasAcumulados}
							onChange={(e, checked) => {
								handleChange(checked, 'apenas_acumulados');
								handleChange(false, 'imprimir_acumulado_demonstrativo_mensal');
							}}
							name='apenas_acumulados'
						/>
					}
					label='Imprimir apenas acumulados'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirAcumulado}
							onChange={(e, checked) => {
								handleChange(checked, 'imprimir_acumulado_demonstrativo_mensal');
								handleChange(false, 'apenas_acumulados');
							}}
							name='imprimir_acumulado_demonstrativo_mensal'
						/>
					}
					label='Imprimir acumulado'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirMedia}
							onChange={(e, checked) => handleChange(checked, 'imprimir_media_demonstrativo_mensal')}
							name='imprimir_media_demonstrativo_mensal'
						/>
					}
					label='Imprimir média dos valores'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirDevedores}
							onChange={(e, checked) => handleChange(checked, 'imprimir_devedores')}
							name='imprimir_devedores'
						/>
					}
					label='Imprimir devedores'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={imprimirAssinatura}
							onChange={(e, checked) => handleChange(checked, 'imprimir_assinatura')}
							name='imprimir_assinatura'
						/>
					}
					label='Imprimir assinaturas'
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={receitaBruta}
							onChange={(e, checked) => handleChange(checked, 'receita_bruta')}
							name='receita_bruta'
						/>
					}
					label='Receita Bruta (Lançada)'
				/>
			</FormGroup>
		</FormControl>
	);
};

export const DemonstrativoMensalDivisor = ({ idCondominio, tipoRelatorio }) => {
	const [dataInicial, setDataInicial] = useState(format(TODAY, 'dd/MM/yyyy'));
	const [dataFinal, setDataFinal] = useState(format(TODAY, 'dd/MM/yyyy'));
	const {
		opcoesImpressao: {
			imprimir_acumulado_demonstrativo_mensal,
			imprimir_media_demonstrativo_mensal,
			apenas_acumulados,
			imprimir_devedores,
			imprimir_assinatura,
			receita_bruta,
		},
		handleChangeOpcoesImpressao,
	} = React.useContext(CheckboxRelatorioContext);
	const [errors, setErrors] = useState({
		dataFinErr: false,
		dataFinErrText: 'Data inválida',
	});
	const [objetoDatas, setObjetoDatas] = useState({
		dataInicial: parse(dataInicial, 'dd/MM/yyyy', new Date()),
		dataFinal: parse(dataFinal, 'dd/MM/yyyy', new Date()),
	});
	useEffect(() => {
		setObjetoDatas({
			dataInicial: parse(dataInicial, 'dd/MM/yyyy', new Date()),
			dataFinal: parse(dataFinal, 'dd/MM/yyyy', new Date()),
		});
	}, [dataInicial, dataFinal]);
	const requestData = {
		id_condominio: idCondominio,
		data_inicial: dataInicial,
		data_final: dataFinal,
		imprimir_acumulado_demonstrativo_mensal: imprimir_acumulado_demonstrativo_mensal,
		imprimir_media_demonstrativo_mensal: imprimir_media_demonstrativo_mensal,
		imprimir_assinatura: imprimir_assinatura,
		apenas_acumulados: apenas_acumulados,
		imprimir_devedores: imprimir_devedores,
		receita_bruta: receita_bruta,
		nome_relatorio: tipoRelatorio.action,
	};
	const parseDate = (data) => parse(data, 'dd/MM/yyyy', new Date());
	const disableOpcoesImpressao = dataInicial === dataFinal;
	useEffect(() => {
		const minDateValidation =
			isAfter(objetoDatas.dataFinal, objetoDatas.dataInicial) ||
			isEqual(objetoDatas.dataFinal, objetoDatas.dataInicial);
		const maxDateValidation = isBefore(
			objetoDatas.dataFinal,
			endOfMonth(
				add(objetoDatas.dataInicial, {
					months: 11,
				})
			)
		);
		if (isValid(objetoDatas.dataInicial) && isValid(objetoDatas.dataFinal))
			if (!minDateValidation || !maxDateValidation) {
				setErrors({
					dataFinErr: true,
					dataFinErrText: 'Data final não pode ser menor que inicial ou maior que um ano',
				});
			} else {
				setErrors((err) => ({
					...err,
					dataFinErr: false,
				}));
			}
	}, [objetoDatas]);
	return (
		<BoxDivisor titulo='Demonstrativo Mensal'>
			<Box display='flex' mb='1em'>
				<RelatorioInputDate
					style={{ marginRight: '1em' }}
					label='Data Inicial'
					handleChange={(_, newValue) => {
						setDataInicial(newValue);
					}}
					onBlur={() => {
						if (!isValid(objetoDatas.dataInicial)) setDataInicial(format(TODAY, 'dd/MM/yyyy'));
					}}
					value={dataInicial}
					fullWidth
				/>
				<RelatorioInputDate
					label='Data Final'
					handleChange={(_, newValue) => {
						setDataFinal(newValue);
						if (!isValid(parseDate(newValue))) {
							setErrors((err) => ({
								...err,
								dataFinErr: true,
								dataFinErrText: 'Data inválida',
							}));
						}
					}}
					value={dataFinal}
					fullWidth
					minDate={dataInicial ? objetoDatas.dataInicial : TODAY}
					maxDate={
						dataInicial
							? endOfMonth(
									add(objetoDatas.dataInicial, {
										months: 11,
									})
							  )
							: add(TODAY, { years: 10 })
					}
					error={errors.dataFinErr}
					helperText={errors.dataFinErr ? errors.dataFinErrText : ''}
				/>
			</Box>
			<Box display='flex'>
				<Box flex={1} mr='1em'>
					<OpcoesImpressaoMensalCheckboxGroup
						disabled={disableOpcoesImpressao}
						handleChange={handleChangeOpcoesImpressao}
						imprimirAcumulado={imprimir_acumulado_demonstrativo_mensal}
						imprimirMedia={imprimir_media_demonstrativo_mensal}
						apenasAcumulados={apenas_acumulados}
						imprimirDevedores={imprimir_devedores}
						imprimirAssinatura={imprimir_assinatura}
						receitaBruta={receita_bruta}
					/>
				</Box>
			</Box>
			<Box display='flex' justifyContent='flex-end'>
				<RelatorioToolbar
					data={requestData}
					tipoRelatorio={tipoRelatorio}
					valid={isValid(objetoDatas.dataInicial) && isValid(objetoDatas.dataFinal) && !errors.dataFinErr}
				/>
			</Box>
		</BoxDivisor>
	);
};
